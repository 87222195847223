import * as React from "react";
import Footer from "../components/Footer/footer";
import Detail from "../components/Detail/detail";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { useParams } from 'react-router-dom';
import ViewApartment from "../components/ViewApartment/viewApartment";
import MenuDetails from "../components/MenuDetails/menuDetails";
import Menu from "../components/Menu/menu";

const pageStyles = {color: "#232129"}

const DetailPage = () => {
  const { idApartment } = useParams();
  return (
    <main style={pageStyles}>
      {idApartment ? <MenuDetails/> :  <Menu/>}
      {idApartment ? (<Detail idApartmentSelcted={idApartment} typeDescr='vendita'/>) : (<ViewApartment typeDescr='vendita'/>)}
      <Footer></Footer>
    </main>
  )
}

export default DetailPage
