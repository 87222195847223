import * as React from "react";
import ContactForm from "../components/ContactForm/contactForm";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import Menu from "../components/Menu/menu";

const pageStyles = {color: "#232129"}

const ContactPage = () => {
  return (
    <main style={pageStyles}>
        <Menu/>
        <ContactForm />
    </main>
  )
}

export default ContactPage;
