import React, { Component } from 'react';
import "./menuDetails.css";
import { Nav, Navbar } from "react-bootstrap";
import { Container, Row, Col, Button} from 'react-bootstrap';
import logo from "../../images/logo.png";
import logoNegativo from "../../images/icons/logo-negativo.svg";
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { faClose } from '@fortawesome/free-solid-svg-icons'
import "../../index.css"


class MenuDetails extends Component {
    constructor(props) {
        super(props);
        this.state={
            itemMenuSel:'',
            showMenu: false
        }
    }

    showMenu = (event) =>{
        this.setState({showMenu:true});
    }
    closeMenu= (event) =>{
        this.setState({showMenu:false});
    }

    componentDidMount() {
        this.setState({itemMenuSel:window.location.pathname});
        this.setState({showMapCity:this.props.showMapCity});
    }
    render() {
        return (
            <Container fluid className="rowNavbar-details no-print">
                
                    <Col xs={4} sm={4} md={8} lg={2} className='mainLogo'>
                        <Link to={'/'}>
                            <div href="#home">
                                <img src={logo} style={{maxWidth: 90}}/>
                            </div> 
                        </Link>  
                    </Col>                    
                    <Col className='navBarMobile' xs={4} sm={4} md={2} lg={8} style={{display:"flex",alignItems: "center", justifyContent: "center"}}> 
                        <Navbar collapseOnSelect expand="lg" bg="white" className='styleNavbar'>   
                            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                                <Nav className="mr-auto containerNavbar">
                                    <Nav.Link href="/" className={this.state.itemMenuSel === '/' || this.state.itemMenuSel === 'home' || this.state.itemMenuSel === '' ? 'itemMenu itemMenuSel':'itemMenu'}>Home</Nav.Link>
                                    <Nav.Link href="/vendita-immobili" className={this.state.itemMenuSel === '/vendita-immobili' ? 'itemMenu itemMenuSel':'itemMenu'}>Vendita</Nav.Link>
                                    <Nav.Link href="/locazione-immobili" className={this.state.itemMenuSel === '/locazione-immobili' ? 'itemMenu itemMenuSel':'itemMenu'}>Locazione</Nav.Link>
                                    <Nav.Link href="/ChiSiamo" className={this.state.itemMenuSel === '/ChiSiamo' ? 'itemMenu itemMenuSel':'itemMenu'}>Chi siamo</Nav.Link>
                                </Nav>
                        </Navbar>
                        
                         
                        <FontAwesomeIcon className='icon-bars' icon={faBars} onClick={() => this.showMenu()}/>
                        { this.state.showMenu && 
                        <div id="mobile-menu" className="mobileMenu">
                            <div className="d-flex w-100 justify-content-between"> 
                                <img src={logoNegativo} className="logoNav" alt="logo-filcasa"/>
                                <FontAwesomeIcon className="cross" icon={faClose} onClick={() => this.closeMenu()}/>
                            </div>
                            <ul className='navList'>
                                <li><a href="/" className={this.state.itemMenuSel === '/' || this.state.itemMenuSel === 'home' || this.state.itemMenuSel === '' ? 'item-mobile-active':''}>Home</a></li>
                                <li><a href="/vendita-immobili" className={this.state.itemMenuSel === '/vendita-immobili' ? 'item-mobile-active':''}>Vendita</a></li>
                                <li><a href="/locazione-immobili" className={this.state.itemMenuSel === '/locazione-immobili' ? 'item-mobile-active':''}>Locazione</a></li>
                                <li><a href="/ChiSiamo" className={this.state.itemMenuSel === '/ChiSiamo' ? 'item-mobile-active':''}>Chi siamo</a></li>
                                <li><a href="/Contatti" className={this.state.itemMenuSel === '/Contatti' ? 'item-mobile-active':''}>Contatti</a></li>
                            </ul>
                            <div>
                                <hr style={{color:"#fff"}}></hr>
                                <div className='nav-contatti'>
                                    <p style={{margin:"20px 0 0 0"}}><strong>FILCASA AGENCY S.R.L</strong></p>
                                    <p>Via Mongi 13 - 20123 Milano</p>
                                    <p>Tel. 02 49523960</p>
                                    <p><a href="mailto:info@filcasaagency.it">info@filcasaagency.it</a></p>
                                </div>
                            </div>
                            
                        </div>
                        }   
                    </Col>
                    <Col className='contactMob' xs={4} sm={4} md={2} lg={2} style={{display:"flex",alignItems: "center"}}>
                        <Link to={'/Contatti'}>
                             <div className='iconMailMobile'></div>
                             <div className='iconMailDesk' >
                            <Button variant="primary" className='mainBtn' onClick={() => this.setState({itemMenuSel:'/contatti'})}>
                                    <div className='contMinBtn'>
                                        <div className='textMainBtn'>CONTATTI</div>
                                        <div className='iconMainButton iconbtnMail'></div>
                                    </div>
                            </Button>
                            </div>
                        </Link>
                    </Col>
                
            </Container>
        )
    }
}

export default MenuDetails;