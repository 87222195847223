import React, { Component, PropTypes } from 'react';
import "./activity.css";
import { Container, Row, Col, Button,Tabs, Tab, Form, Card} from 'react-bootstrap';

class Activity extends Component {

  componentDidMount() {
    document.title = "Chi siamo"; 
 }

    render() {
        return (
            <>
            <Container fluid>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <div className='containerHeader'>
                            <h1 className='titleHaed'><b>Attività</b> Filcasa Agency</h1>
                            <div className='subtitleHead'>
                                <p>Da oltre 30 anni in prima linea nel mercato immobiliare, accanto alla tradizionale attività di intermediazione Filcasa Agency svolge attività di consulenza e collabora con un pool di professionisti, avvocati, amministratori di condominio, società e fondi di gestione patrimoniale sia per la vendita che per la locazione di singole unità e interi edifici.</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container fluid>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <div className='containerBody'>
                            <div className='tabBody'>
                                <div className='boxBodyIcon'>
                                    <div className='bodyIconServizi'></div>
                                </div>
                                <div className='boxBodyFirstText'>
                                    <div className='containerFirstText'>
                                        <h2 className='numberFirstText'>01</h2>
                                        <h2 className='titleFirstText'>Servizi di intermediazione</h2>
                                        <p className='subtitleFirstText'>nelle compravendite e nelle locazioni di interi edifici o di singole unità</p>
                                    </div>
                                </div>
                                <div className='boxBodySecondText'>
                                    <div>
                                        <p className='subtitleSecondText'>Il servizio di consulenza viene fornito sia a grandi gruppi – industriali, bancari e assicurativi – che a privati che mettono in vendita o in locazione le loro proprietà o che le acquisiscono. Gli immobili selezionati Filcasa Agency sono distribuiti su tutto il territorio milanese e nei suoi dintorni.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='tabBody'>
                                <div className='boxBodyIcon'>
                                    <div className='bodyIconIndagini'></div>
                                </div>
                                <div className='boxBodyFirstText'>
                                    <div className='containerFirstText'>
                                        <h2 className='numberFirstText'>02</h2>
                                        <h2 className='titleFirstText'>Indagini</h2>
                                        <p className='subtitleFirstText'>di mercato </p>
                                    </div>
                                </div>
                                <div className='boxBodySecondText'>
                                    <div>
                                        <p className='subtitleSecondText'>Filcasa Agency mantiene un occhio sempre attento al mercato e analizza i dati anche tramite strumenti e applicativi dedicati.<br/>Grazie all’esperienza sul campo, vengono individuate per ogni situazione le opportunità di profitto e i fattori di rischio.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='tabBody'>
                                <div className='boxBodyIcon'>
                                    <div className='bodyIconValutazioni'></div>
                                </div>
                                <div className='boxBodyFirstText'>
                                    <div className='containerFirstText'>
                                        <h2 className='numberFirstText'>03</h2>
                                        <h2 className='titleFirstText'>Valutazioni </h2>
                                        <h2 className='titleFirstText'>Immobiliari</h2>
                                        <p className='subtitleFirstText'>consulenze e perizie</p>
                                    </div>
                                </div>
                                <div className='boxBodySecondText'>
                                    <div>
                                        <p className='subtitleSecondText'>L’esperienza ultratrentennale nel settore garantisce la massima correttezza delle procedure valutative e la loro aderenza alla situazione reale e alla risposta del mercato.<br/>I tecnici qualificati Filcasa Agency stabiliscono il valore commerciale dell’immobile, lo stato di conservazione e la regolarità rispetto alle normative.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='tabBody'>
                                <div className='boxBodyIcon'>
                                    <div className='bodyIconAssistenza'></div>
                                </div>
                                <div className='boxBodyFirstText'>
                                    <div className='containerFirstText'>
                                        <h2 className='numberFirstText'>04</h2>
                                        <h2 className='titleFirstText'>Assistenza</h2>
                                        <p className='subtitleFirstText'>tecnico - legale</p>
                                    </div>
                                </div>
                                <div className='boxBodySecondText'>
                                    <div>
                                        <p className='subtitleSecondText'>Filcasa Agency garantisce ai propri clienti un’assistenza completa e professionale, dalle fasi di vendita fino al rogito.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='tabBody'>
                                <div className='boxBodyIcon'>
                                    <div className='bodyIconHomeStaging'></div>
                                </div>
                                <div className='boxBodyFirstText'>
                                    <div className='containerFirstText'>
                                        <h2 className='numberFirstText'>05</h2>
                                        <h2 className='titleFirstText'>Home Staging</h2>
                                    </div>
                                </div>
                                <div className='boxBodySecondText'>
                                    <div>
                                        <p className='subtitleSecondText'>Grazie a un equipe di architetti e tecnici professionisti, Filcasa Agency garantisce, per alcuni immobili, un’ottimizzazione degli spazi tramite un attento studio di interior volto a valorizzare gli appartamenti.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='tabBodyLast'>
                                <div className='lastTab'>
                                    <div>
                                        <p className='lastTabtext'>I nostri professionisti, regolarmente iscritti presso la Camera di Commercio e con un’esperienza trentennale nel settore, sono disponibili per perizie, consulenze, indagini di mercato e stime e per qualsiasi necessità in campo immobiliare.</p>
                                    </div>
                                </div>
                                <div className='lastTab'>
                                    <div>
                                        <p className='lastTabtext'>Per tutti gli immobili in mandato Filcasa Agency garantisce spazi pubblicitari sui principali portali immobiliari e sulle più importanti riviste specializzate, assicurando un’assistenza tecnico-legale completa in tutte le fasi della vendita fino al rogito.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            </>
        );
    }
}

export default Activity;