import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import HomePage from "./Pages/Home";
import DetailPage from "./Pages/DetailPage";
import NotFoundPage from "./Pages/PageNotFound";
import Menu from "./components/Menu/menu";
import ContactPage from "./Pages/ContactPage";
import ActivityPage from "./Pages/ActivityPage";
import LocazionePage from "./Pages/Locazione";
import PrivacyPage from "./Pages/PrivacyPage";
import ScrollTopView from "./components/ScrollTopView/scrollTopView";
import Detail from "./components/Detail/detail";
import CookieConsent, { Cookies } from "react-cookie-consent";
import * as ReactGA from "react-ga";
import { InfoPopup } from './components/InfoPopup';
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import logo from "./images/logo.png";
import logoNegativo from "./images/icons/logo-negativo.svg";

// export default function App() {
//   return (
//     <BrowserRouter>
//       <ScrollTopView>
//         <Menu />
//         <Routes>
//           <Route exact path="/" element={<HomePage />} />
//           <Route path="home" element={<HomePage />} />
//           <Route exact path="/vendita/:id" element={<DetailPage />} />
//           <Route path="attivita" element={<ActivityPage />} />
//           <Route path="locazione" element={<LocazionePage />} />
//           <Route path="contatti" element={<ContactPage />} />
//           <Route path="*" element={<NotFoundPage />} />
//         </Routes>
//       </ScrollTopView>
//     </BrowserRouter>
//   );
// }

// ReactDOM.render(<App />, document.getElementById("root"));
const GOOGLE_ANALYTICS_ID = "UA-90876035-1";
const env = 'prod'
const initGA = (id) => {
  if (env === "prod") {
    ReactGA.initialize(id);
  }
}; 

class App extends React.Component {
  constructor(props) {
    super(props);
    
  }
  

  handleAcceptCookie = () => {
    if (GOOGLE_ANALYTICS_ID) {
      initGA(GOOGLE_ANALYTICS_ID);
    }
    gtag('config', 'G-71VPNWQ4NL');

    window.dataLayer = window.dataLayer || [];
    function gtag(){window.dataLayer.push(arguments);}
    gtag('js', new Date());
  };


  handleDeclineCookie = () => {
    Cookies.remove("_ga");
    //Cookies.remove("_ga_FPVNSRDTQQ");
    Cookies.remove("_gid");
  };

  render() {
    return (
      <BrowserRouter>
      <ScrollTopView>
        <Routes>
          <Route exact path="/home" element={<HomePage/>} />
          <Route exact path="/" element={<HomePage/>} />
          {/* <Route path="home" element={<HomePage />} /> */}
          <Route exact path="/vendita-immobili/:idApartment" element={<DetailPage />} />
          <Route exact path="vendita-immobili" element={<DetailPage />} />
          <Route path="ChiSiamo" element={<ActivityPage />} />
          <Route path="locazione-immobili" element={<LocazionePage/>} />
          <Route exact path="/locazione-immobili/:idApartment" element={<LocazionePage />} />
          <Route path="Contatti" element={<ContactPage />} />
          <Route path="*" element={<NotFoundPage />} />
          <Route path="/privacy" element={<PrivacyPage />} />
        </Routes>
        {
          // !window.sessionStorage.popup
          // ?
          // <InfoPopup></InfoPopup>
          // :
          // <></>
        }
        <FloatingWhatsApp 
        phoneNumber="390249523960"
        statusMessage="Feel at Home"
        accountName="FilCasa Agency"
        avatar={logoNegativo}
        chatMessage="Scrivici per ricevere informazioni sulla ricerca della tua casa! 🏠"
        notification={true}/>
       
        <CookieConsent
          enableDeclineButton
          debug={false}
          expires={90}
          buttonText="ACCETTA TUTTI"
          declineButtonText="RIFIUTA TUTTI"
          onAccept={this.handleAcceptCookie}
          onDecline={this.handleDeclineCookie}          
          >Questo sito o gli strumenti terzi da questo utilizzati si avvalgono di cookie 
          necessari al funzionamento ed utili alle finalità illustrate nella cookie policy. 
          Se vuoi saperne di più o negare il consenso a tutti o ad alcuni cookie, 
          consulta la <a href="/privacy/#cookie">cookie policy</a>. Chiudendo questo banner, scorrendo questa pagina, 
          cliccando su un link o proseguendo la navigazione in altra maniera, 
          acconsenti all’uso dei cookie.</CookieConsent>
      </ScrollTopView> 
    </BrowserRouter>
    );
  }
}
export default App;
