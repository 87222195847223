import * as React from "react";
import axios from 'axios';
import "./footer.css";
import { Container, Row, Col, Button} from 'react-bootstrap';
import "react-multi-carousel/lib/styles.css";
import Parser from 'html-react-parser';
import { DATA_LOGIN } from '../../constants'
import logo from "../../images/logo.png";
import feelAtHome from "../../images/feelAtHome.png";
import { BrowserRouter as Router, Switch, Route, Link, Navigate } from 'react-router-dom';
import { Block } from "@mui/icons-material";

export default class Home extends React.Component {
    
    state = {        
    }
    
    componentDidMount() {
    } 
    navExt() {
        window.location.href = 'https://www.filcasa.it';
    }
    render() {
        return (
            <>
                <div className="containerFooter">
                    <Container fluid>
                        <Row style={{marginTop:30}} className='footer-section-container no-print'>
                            <Col xs={12} sm={12} md={12} lg={3} > </Col>
                            <Col xs={12} sm={12} md={12} lg={9} >
                                <img src={logo} className="logoContainer" alt="logo filcasa"/>
                            </Col>
                        </Row>
                        <Row style={{marginTop:30}} className='footer-section-container no-print'>
                            <Col xs={12} sm={4} md={4} lg={3} >
                                <img src={feelAtHome}/>
                                <p className="subFooter">Entra nel mondo <b>Filcasa - Gruppo Paletti</b></p>
                                {/* <Link to={'/'}> */}
                                    <Button variant="primary" className='btnFooter' onClick={() => this.navExt()}>
                                            <div className='contMinBtn'>
                                                <div className='textBtnFooter'>SCOPRI DI PIÙ</div>
                                                <div className='iconMainButton iconbtnFooter'></div>
                                            </div>
                                    </Button>
                                {/* </Link> */}
                            </Col>
                            <Col xs={12} sm={2} md={2} lg={3} className="boxInfoFooter">  
                                <h5 className="title-footer">Filcasa Agency s.r.l.</h5>
                                <p className="text-footer">Sede legale e operativa: Via Pindemonte 2 – 20129 Milano </p>
                                
                                <p className="text-footer">Tel. 02 49523960 - Fax 02 49523974</p>
                                <a href="mailto:info@filcasaagency.it" className="text-footer">info@filcasaagency.it</a>
                                <a href="mailto:filcasaagency@pec.filcasaagency.it" className="text-footer">filcasaagency@pec.filcasaagency.it</a>
                            </Col>
                            <Col xs={12} sm={2} md={2} lg={2} className="boxInfoFooter">
                                <h5 className="title-footer">Sitemap</h5>
                                <a href="/" className="text-footer text-footer-site">Home</a><br/>
                                <a href="/vendita-immobili" className="text-footer text-footer-site">Vendita</a><br/>
                                <a href="/locazione-immobili" className="text-footer text-footer-site">Locazione</a><br/>
                                <a href="/ChiSiamo" className="text-footer text-footer-site">Chi siamo</a><br/>
                                <a href="/Contatti" className="text-footer text-footer-site">Contatti</a>
                            </Col>
                        </Row> 
                        <div className="print"> 
                            <div> Sede legale e operativa: Via Pindemonte 2 – 20129 Milano </div> 
                            <div> Tel. 02 49523960 </div> 
                            <div> info@filcasaagency.it </div> 
                            <div> filcasaagency@pec.filcasaagency.it </div>
                        </div>                       
                    </Container>
                    <div className="containerFooterLink no-print">
                        <Row className='footerContact'>
                            <Col xs={12} sm={12} md={6} lg={6} className='colFooterSx'>
                                <div className='boxFooterGen'>
                                    <p className='subtitleLowTitle'>Filcasa Agency S.r.l. | P.I. 12906720151</p>
                                </div>
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={6} className='colFooterDx'>
                                <div className='boxFooterDx boxFooterGen'>
                                    <a className='subtitleLow' href = "/privacy">Privacy & Cookies Policy</a>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </>
        )
    }
}