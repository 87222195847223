import * as React from "react";
import Footer from "../components/Footer/footer";
import Locazione from "../components/Locazione/locazione"
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { useParams } from 'react-router-dom';
import ViewApartment from "../components/ViewApartment/viewApartment";
import Detail from "../components/Detail/detail";
import Menu from "../components/Menu/menu";
import MenuDetails from "../components/MenuDetails/menuDetails";

const pageStyles = {color: "#232129"}

const LocazionePage = () => {
  // return (
  //   <main style={pageStyles}>
  //     <Locazione />
  //     <Footer />
  //   </main>
  // )
  const { idApartment } = useParams();
  return (
    <main style={pageStyles}>
      {idApartment ? <MenuDetails/> :  <Menu/>}
      {idApartment ? (<Detail idApartmentSelcted={idApartment} typeDescr='locazione'/>) : (<ViewApartment typeDescr='locazione'/>)}
      <Footer></Footer>
    </main>
  )
}

export default LocazionePage
