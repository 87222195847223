import * as React from "react";
import Footer from "../components/Footer/footer";
import Activity from "../components/Activity/activity";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import Menu from "../components/Menu/menu";

const pageStyles = {color: "#232129"}

const DetailPage = () => {
  return (
    <main style={pageStyles}>
      <Menu/>
      <Activity />
      <Footer />
    </main>
  )
}

export default DetailPage
