import React, { Component, PropTypes } from 'react';
import "./contactForm.css";
import { Container, Row, Col, Button,Tabs, Tab, Form, Card} from 'react-bootstrap';
import { AiOutlinePhone } from 'react-icons/ai';
import { AiOutlineMail } from 'react-icons/ai';
import { AiOutlineEnvironment } from 'react-icons/ai';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Toast from '../Toast/Toast.js';
//  toast.configure()
//  function GeeksforGeeks(){
//      const notify = ()=>{
//          // Set to 3sec
//          //toast.success('successful', {autoClose:3000})
//          // User have to close it
        
          
//      }
    
//  }
const checktoast = false;

class ContactForm extends Component {
    componentDidMount() {
        document.title = "Contatti"; 
     }

    render() {
        return (
            <>
                <Container fluid className='containerContact'>
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6} className='dimenSections'>
                            <div className='containerTitle'>
                                <h1 className='mainTitle'>Sei interessato ad un immobile</h1>
                                <h2 className='subtitle'>o necessiti di maggiori informazioni?</h2>
                                <h4 className='subtitleContact'>Contattaci direttamente o compila il form con la tua richiesta</h4>
                            </div>
                            <div className='boxAddress'>
                                <div className='rowBoxAddress'>
                                    <div className='iconBoxAddress'><AiOutlineEnvironment/></div>
                                    <span className='textBoxAddress'>Sede legale e operativa: Via Pindemonte 2 – 20129 Milano </span>
                                </div>
                                <div className='rowBoxAddress'>
                                    <div className='iconBoxAddress'><AiOutlinePhone /></div>
                                    <span className='textBoxAddress'>Tel. 02 49.52.39.60</span>
                                </div>
                                <div className='rowBoxAddress'>
                                    <div className='iconBoxAddress'><AiOutlineMail/></div>
                                    <div className='textBoxAddress' style={{display:'grid'}}>
                                        <span>Mail: <a className="link-color" href="mailto:info@filcasaagency.it">info@filcasaagency.it</a></span>
                                        <span>Pec: <a className="link-color" href="mailto:filcasaagency@pec.filcasaagency.it">filcasaagency@pec.filcasaagency.it</a></span>
                                    </div>
                                </div>
                            </div>
                            </Col>
                            {/*<Col xs={12} sm={12} md={6} lg={6} className='dxdimenSections'>
                            <Row style={{ padding: '20px' }}>
                                <Col xs={12} sm={12} md={6} lg={6}>
                                    <h4 className='titleLow'>Agenzia tricolore</h4>
                                    <h5 className='subtitleLow'>Piazza Tricolore 4 - 20129 Milano</h5>
                                    <h5 className='subtitleLow'>Tel. 02 76394738 | Fax 02 76394802</h5>
                                    <h5 className='subtitleLow'><a className="link-color" href="mailto:tricolore@filcasaagency.it">tricolore@filcasaagency.it</a></h5>
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={6}>
                                    <h4 className='titleLow'>Agenzia Losanna</h4>
                                    <h5 className='subtitleLow'>Via Losanna 15 - 20154 Milano</h5>
                                    <h5 className='subtitleLow'>Tel. 02 49523964 | Fax 02 36552524</h5>
                                    <h5 className='subtitleLow'><a className="link-color" href="mailto:losanna@filcasaagency.it">losanna@filcasaagency.it</a></h5>
                                </Col>
                                
                            </Row>
                            </Col>*/}
                        <Col xs={12} sm={12} md={6} lg={6} className='dimenSections'>
                            {/* <div className='containerForm'>
                                <Form>
                                    <Row style={{marginBottom:'30px'}}>
                                        <Col xs={12} sm={12} md={6} lg={6} className="containerInputForm">
                                            <Form.Label className='labelForm'>Nome*</Form.Label>
                                            <Form.Control placeholder="Inserisci il tuo nome" className='inputForm'/>
                                        </Col>
                                        <Col xs={12} sm={12} md={6} lg={6} className="containerInputForm">
                                            <Form.Label className='labelForm'>Cognome*</Form.Label>
                                            <Form.Control placeholder="Cognome*" className='inputForm'/>
                                        </Col>
                                        <Col xs={12} sm={12} md={6} lg={6} className="containerInputForm">
                                            <Form.Label className='labelForm'>Mail*</Form.Label>
                                            <Form.Control type="email" placeholder="Inserisci l'indirizzo mail" className='inputForm'/>
                                        </Col>
                                        <Col xs={12} sm={12} md={6} lg={6} className="containerInputForm">
                                            <Form.Label className='labelForm'>Telefono*</Form.Label>
                                            <Form.Control type="tel" placeholder="Inserisci il tuo numero di telefono" className='inputForm'/>
                                        </Col>
                                        <Col xs={12} sm={12} md={12} lg={12} className="containerInputForm">
                                            <Form.Label className='labelForm'>Messaggio*</Form.Label>
                                            <Form.Control placeholder="Inserisci il tuo messaggio" className='inputForm'/>
                                        </Col>
                                        <Col xs={12} sm={12} md={12} lg={12}>
                                            <div className='secondTitleForm'>L'interessato dichiara:</div>
                                            <Form.Group className="labelCheckbox" controlId="formBasicCheckbox">
                                                <Form.Check type="checkbox" label="di aver ricevuto idonea informativa e di prestare il suo consenso, esplicito ed inequivocabile, in relazione alle finalità del trattamento, come espresso al punto 3, lettera b, dell'infromativa per il trattamento dei dati; *" />
                                            </Form.Group>
                                            <Form.Group className="labelCheckbox" controlId="formBasicCheckbox">
                                                <Form.Check type="checkbox" label="di acconsentire alla registrazione dei miei dati per ricevere un servizio personalizzato sulle mie reali esigenze d'acquisto;" />
                                            </Form.Group>
                                            <Form.Group className="labelCheckbox" controlId="formBasicCheckbox">
                                                <Form.Check type="checkbox" label="di acconsentire all'invio di comunicazione di marketing, per mezzo di sistemativi informatici autorizzati, ivi inclusi comunicazioni commerciali o promozionali a mezzo email o SMS, ovvero pe ricerche ed analisi di mercato." />
                                            </Form.Group>
                                            <p className='labelCheckbox'>*campi obbligatori</p>
                                        </Col>
                                    </Row>
                                    <Button variant="primary" type="submit" className='mainBtn' onClick={ toast.success('successful',
                                    {position: toast.POSITION.BOTTOM_RIGHT})}>
                                        <div className='contMinBtn'>
                                            <div className='textMainBtn'>Invia</div>
                                            <div className='iconMainButton iconBtnProced'></div>
                                        </div>
                                    </Button>
                                  
                                </Form>
                            </div> */}
                        </Col>
                    </Row>
                    <Row className='footerContact'>
                        <Col xs={12} sm={12} md={6} lg={6} className='colFooterSx'>
                            <div className='boxFooterGen'>
                                <p className='subtitleLowTitle'>Filcasa Agency S.r.l. | P.I. 12906720151</p>
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} className='colFooterDx'>
                            <div className='boxFooterDx boxFooterGen'>
                                <a className='subtitleLow' href="/privacy">Privacy & Cookies Policy</a>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

export default ContactForm;