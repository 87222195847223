import React, { Component, PropTypes } from 'react';
import axios from 'axios';
import "./bodyHome.css";
import { Container, Row, Col, Button, Tabs, Tab, Form, Card, DropdownButton, Dropdown} from 'react-bootstrap';
import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
import Parser from 'html-react-parser';
import { DATA_LOGIN } from '../../constants'
import { IoIosGitCompare,IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { BrowserRouter, Switch, Route, Navigate, Link } from "react-router-dom";
import gMapStyle from "../../style/gMapStyle";
import { GoogleMap, useJsApiLoader, Polygon, LoadScript } from '@react-google-maps/api';
import ImagePeopleAgency from "../../images/peopleAgency.jpg";
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Slider from '@mui/material/Slider';
import Checkbox from '@mui/material/Checkbox';
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose } from '@fortawesome/free-solid-svg-icons'


const mapContainerStyle = { 
    height: "500px",
    width: "600px"
}


  const mapContainerStyle1920 = {
    height: "600px",
    width: "800px"
  }
const bodyExport = {
    Token: "",
    Tipologia: "",
    Comune: [],
    Destinazione: [],
    ValoreImmobileDa: null,
    ValoreImmobileA: null,
    SuperficieDa: null,
    SuperficieA: null,
    Locali: null
}

const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 2
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
};

const center = { lat: 45.459570610581904,
    lng: 9.163184669589096 }

const centroStorico = [
    { lat: 45.46147352228216, lng: 9.164215641951856},
    { lat: 45.467713629021645, lng: 9.16639274468963},
    { lat: 45.47752452365164, lng: 9.181008555851013},
    { lat: 45.48120804006813, lng: 9.182367863092164},
    { lat: 45.480065429425764, lng: 9.189559042179026},
    { lat: 45.479457235151365, lng: 9.19423020765898},
    { lat: 45.47417797623128, lng: 9.20479098502093},
    { lat: 45.473697051748196, lng: 9.205501025045432},
    { lat: 45.46713715807906, lng: 9.205869887681532},
    { lat: 45.46222603677896, lng: 9.206762090271484},
    { lat: 45.45811883036394, lng: 9.204890229232719},
    { lat: 45.45674164882762, lng: 9.204076485200188},
    { lat: 45.45231603052424, lng: 9.201983675479804},
    { lat: 45.45183382891125, lng: 9.200827472734066},
    { lat: 45.452217169133256, lng: 9.198831834672042},
    { lat: 45.45203330507182, lng: 9.196095828687675},
    { lat: 45.451985460927624, lng: 9.189932207188063},
    { lat: 45.45252942857895, lng: 9.185402062972493},
    { lat: 45.452795120473155, lng: 9.180783481067486},
    { lat: 45.453096564848245, lng: 9.179624866427307},
    { lat: 45.452887259982646, lng: 9.17724036744628},
    { lat: 45.46147352228216, lng: 9.164215641951856},
]

const WashingtonFiera = [
    { lat: 45.46147352228216, lng: 9.164215641951856},
    { lat: 45.467713629021645, lng: 9.16639274468963},
    { lat: 45.47752452365164, lng: 9.181008555851013},
    { lat: 45.48781523731526, lng: 9.156783816664975},
    { lat: 45.490167666957376, lng: 9.151496655555954},
    { lat: 45.485109122470995, lng: 9.143817385940338},
    { lat: 45.483687115072485, lng: 9.143059263698532},
    { lat: 45.47734035245628, lng: 9.142623392351735},
    { lat: 45.46587767351897, lng: 9.146180411728606},
    { lat: 45.455194473028506, lng: 9.152459596900888},
    { lat: 45.45941415157269, lng: 9.165852188152758},
    { lat: 45.46001640210163, lng: 9.166495049132504},
    { lat: 45.46147352228216, lng: 9.164215641951856},
]

const Sempione = [
    { lat: 45.47752452365164, lng: 9.181008555851013},
    { lat: 45.48781523731526, lng: 9.156783816664975},
    { lat: 45.490167666957376, lng: 9.151496655555954},
    { lat: 45.496968937599725, lng: 9.162207506365514},
    { lat: 45.48454681350399, lng: 9.180143605190764},
    { lat: 45.48120804006813, lng: 9.182367863092164},
    { lat: 45.47752452365164, lng: 9.181008555851013},
]

const Loreto = [
    { lat: 45.48120804006813, lng: 9.182367863092164},
    { lat: 45.480065429425764, lng: 9.189559042179026},
    { lat: 45.479457235151365, lng: 9.19423020765898},
    { lat: 45.47417797623128, lng: 9.20479098502093},
    { lat: 45.48581271432765, lng: 9.217025766601054},
    { lat: 45.49406986765262, lng: 9.200248581026859},
    { lat: 45.497909908940535, lng: 9.186764624211694},
    { lat: 45.49756835649549, lng: 9.184220942518381},
    { lat: 45.49036692928878, lng: 9.180995897778327},
    { lat: 45.48673822064472, lng: 9.176965724032906},
    { lat: 45.48454681350399, lng: 9.180143605190764},
]

const Navigli = [
    { lat: 45.455194473028506, lng: 9.152459596900888},
    { lat: 45.45941415157269, lng: 9.165852188152758},
    { lat: 45.46001640210163, lng: 9.166495049132504},
    { lat: 45.452887259982646, lng: 9.17724036744628},
    { lat: 45.453096564848245, lng: 9.179624866427307},
    { lat: 45.452795120473155, lng: 9.180783481067486},
    { lat: 45.45252942857895, lng: 9.185402062972493},
    { lat: 45.451985460927624, lng: 9.189932207188063},
    { lat: 45.45203330507182, lng: 9.196095828687675},
    { lat: 45.452217169133256, lng: 9.198831834672042},
    { lat: 45.45183382891125, lng: 9.200827472734066},
    { lat: 45.45231603052424, lng: 9.201983675479804},
    { lat: 45.44694353360185, lng: 9.210733632223032},
    { lat: 45.44583437428013, lng: 9.18851144133567},
    { lat: 45.44334259338251, lng: 9.179501922829065},
    { lat: 45.443931038741674, lng: 9.167137055650109},
    { lat: 45.44418907351236, lng: 9.164879696230344},
    { lat: 45.44481434153334, lng: 9.162480925331955},
    { lat: 45.445446283071306, lng: 9.160633653387727},
    { lat: 45.44666841668427, lng: 9.158563197739632},
    { lat: 45.447091719811276, lng: 9.158118860734977},
    { lat: 45.44908495167594, lng: 9.157057916155631},
    { lat: 45.450204539322144, lng: 9.15533415107287},
]

const Montenero = [
    { lat: 45.45231603052424, lng: 9.201983675479804},
    { lat: 45.45674164882762, lng: 9.204076485200188},
    { lat: 45.45811883036394, lng: 9.204890229232719},
    { lat: 45.46222603677896, lng: 9.206762090271484},
    { lat: 45.46713715807906, lng: 9.205869887681532},
    { lat: 45.473697051748196, lng: 9.205501025045432},
    { lat: 45.47417797623128, lng: 9.20479098502093},
    { lat: 45.48581271432765, lng: 9.217025766601054},
    { lat: 45.46827863005173, lng: 9.217449507985814},
    { lat: 45.468190139959205, lng: 9.223786812841537},
    { lat: 45.46217445404514, lng: 9.223869898080872},
    { lat: 45.46217322887975, lng: 9.217654884059622},
    { lat: 45.456579243306514, lng: 9.217694404995187},
    { lat: 45.44694353360185, lng: 9.210733632223032},
]

const Susa = [
    { lat: 45.468190139959205, lng: 9.223786812841537},
    { lat: 45.46217445404514, lng: 9.223869898080872},
    { lat: 45.46217322887975, lng: 9.217654884059622},
    { lat: 45.456579243306514, lng: 9.217694404995187},
    { lat: 45.44694353360185, lng: 9.210733632223032},
    { lat: 45.44583940264645, lng: 9.212942749325418},
    { lat: 45.44783555048265, lng: 9.259510820317413},
    { lat: 45.4591507339667, lng: 9.251801402772163},
    { lat: 45.4710803741923, lng: 9.252460320106524},
    { lat: 45.47125603960684, lng: 9.248543212132494},
    { lat: 45.47096169574896, lng: 9.245761856271075},
    { lat: 45.47015762140177, lng: 9.245166624932532},
    { lat: 45.46958400833077, lng: 9.241618455137749},
    { lat: 45.4697520340382, lng: 9.237287530136244},
    { lat: 45.46834477714145, lng: 9.23280138379439},
]

const Lambrate = [
    { lat: 45.48581271432765, lng: 9.217025766601054},
    { lat: 45.46827863005173, lng: 9.217449507985814},
    { lat: 45.468190139959205, lng: 9.223786812841537},
    { lat: 45.46834477714145, lng: 9.23280138379439},
    { lat: 45.4697520340382, lng: 9.237287530136244},
    { lat: 45.46958400833077, lng: 9.241618455137749},
    { lat: 45.47015762140177, lng: 9.245166624932532},
    { lat: 45.47096169574896, lng: 9.245761856271075},
    { lat: 45.47125603960684, lng: 9.248543212132494},
    { lat: 45.4710803741923, lng: 9.252460320106524},
    { lat: 45.47662102269289, lng: 9.254197257085409},
    { lat: 45.48383046687485, lng: 9.251309889275365},
    { lat: 45.49110926343957, lng: 9.252363858024207},
    { lat: 45.49613628958832, lng: 9.25548618666735},
    { lat: 45.50029558163383, lng: 9.255852584811969},
    { lat: 45.50260790821067, lng: 9.256939043272357},
    { lat: 45.5061529595337, lng: 9.249220270766035},
    { lat: 45.49310177476243, lng: 9.231869795162416},
    { lat: 45.49204163462516, lng: 9.228848501615316},
    { lat: 45.49145250498545, lng: 9.226244335606397},
    { lat: 45.48950160719311, lng: 9.223242196651611},
    { lat: 45.48732902300309, lng: 9.220887373289889},
]

const Pirelli = [
    { lat: 45.48581271432765, lng: 9.217025766601054},
    { lat: 45.49406986765262, lng: 9.200248581026859},
    { lat: 45.52617242021473, lng: 9.214599135360407},
    { lat: 45.5061529595337, lng: 9.249220270766035},
    { lat: 45.49310177476243, lng: 9.231869795162416},
    { lat: 45.49204163462516, lng: 9.228848501615316},
    { lat: 45.49145250498545, lng: 9.226244335606397},
    { lat: 45.48950160719311, lng: 9.223242196651611},
    { lat: 45.48732902300309, lng: 9.220887373289889},
]

const Bovisa = [
    { lat: 45.52617242021473, lng: 9.214599135360407},
    { lat: 45.49406986765262, lng: 9.200248581026859},
    { lat: 45.497909908940535, lng: 9.186764624211694},
    { lat: 45.49756835649549, lng: 9.184220942518381},
    { lat: 45.49036692928878, lng: 9.180995897778327},
    { lat: 45.48673822064472, lng: 9.176965724032906},
    { lat: 45.496968937599725, lng: 9.162207506365514},
    { lat: 45.515749085356035, lng: 9.1223731482639},
    { lat: 45.52205473533669, lng: 9.150911812159435},
    { lat: 45.536456198675864, lng: 9.163008380486021}, 
    { lat: 45.53430600142605, lng: 9.173470249270878},
    { lat: 45.532810021468244, lng: 9.193002546976194},
    { lat: 45.52850561285415, lng: 9.21008031837612},
]

const Lodi = [
    { lat: 45.44694353360185, lng: 9.210733632223032},
    { lat: 45.44583437428013, lng: 9.18851144133567},
    { lat: 45.44334259338251, lng: 9.179501922829065},
    { lat: 45.44370431568185, lng: 9.172606410085551},
    { lat: 45.437878063954216, lng: 9.17133217582161}, 
    { lat: 45.433241832272856, lng: 9.168378075733235}, 
    { lat: 45.4290481618726, lng: 9.163090839536096}, 
    { lat: 45.42317395629132, lng: 9.159951744675709}, 
    { lat: 45.418313003567256, lng: 9.154465333211595},
    { lat: 45.41473606033393, lng: 9.160803589128234},
    { lat: 45.40752668672762, lng: 9.162848671018498},
    { lat: 45.39651392280874, lng: 9.180198699390141},
    { lat: 45.38747284552316, lng: 9.188581056024692},
    { lat: 45.393388109825864, lng: 9.20345694059592},
    { lat: 45.40479399695664, lng: 9.226802054624839},
    { lat: 45.40868824057536, lng: 9.22977394219666},
    { lat: 45.4101907078822, lng: 9.240397381926538},
    { lat: 45.41831237581309, lng: 9.24709723016886},
    { lat: 45.42690302083324, lng: 9.2457503720563},
    { lat: 45.433652993889105, lng: 9.25526128615409},
    { lat: 45.44783555048265, lng: 9.259510820317413},
    { lat: 45.44583940264645, lng: 9.212942749325418},
]

const Famagosta = [
    { lat: 45.44370431568185, lng: 9.172606410085551},
    { lat: 45.437878063954216, lng: 9.17133217582161}, 
    { lat: 45.433241832272856, lng: 9.168378075733235}, 
    { lat: 45.4290481618726, lng: 9.163090839536096}, 
    { lat: 45.42317395629132, lng: 9.159951744675709}, 
    { lat: 45.418313003567256, lng: 9.154465333211595},
    { lat: 45.43527922299312, lng: 9.109707410789978},
    { lat: 45.435744906396984, lng: 9.11405924193976},
    { lat: 45.43907281936416, lng: 9.125953247974053},
    { lat: 45.44077121676301, lng: 9.128038709104175},
    { lat: 45.444015381150116, lng: 9.137645217703119},
    { lat: 45.44809760374368, lng: 9.157601254851215},
    { lat: 45.44666841668427, lng: 9.158563197739632},
    { lat: 45.445446283071306, lng: 9.160633653387727},
    { lat: 45.44481434153334, lng: 9.162480925331955},
    { lat: 45.44418907351236, lng: 9.164879696230344},
    { lat: 45.443931038741674, lng: 9.167137055650109},
]

const Lorenteggio = [
    { lat: 45.44809760374368, lng: 9.157601254851215},
    { lat: 45.444015381150116, lng: 9.137645217703119},
    { lat: 45.44077121676301, lng: 9.128038709104175},
    { lat: 45.43907281936416, lng: 9.125953247974053},
    { lat: 45.435744906396984, lng: 9.11405924193976},
    { lat: 45.43527922299312, lng: 9.109707410789978},
    { lat: 45.43942747114099, lng: 9.107272768042748},
    { lat: 45.44404668756187, lng: 9.106510875951475},
    { lat: 45.45107228068368, lng: 9.10194352667241},
    { lat: 45.45672160107742, lng: 9.07740005119664},
    { lat: 45.4638356911161, lng: 9.075671079180301},
    { lat: 45.472104437389774, lng: 9.079775032139654},
    { lat: 45.4892116891013, lng: 9.06597379881999},
    { lat: 45.487451163688455, lng: 9.076535737668177},
    { lat: 45.48385489360591, lng: 9.086754015476966},
    { lat: 45.47581495915736, lng: 9.114642098004575},
    { lat: 45.469646609822576, lng: 9.125135075099363},
    { lat: 45.46818732415618, lng: 9.129663808816524},
    { lat: 45.46742758743489, lng: 9.137390404214296},
    { lat: 45.46718901984114, lng: 9.145704845960111},
    { lat: 45.46587767351897, lng: 9.146180411728606},
    { lat: 45.455194473028506, lng: 9.152459596900888},
    { lat: 45.450204539322144, lng: 9.15533415107287},
    { lat: 45.44908495167594, lng: 9.157057916155631},
]

const Trenno = [
    { lat: 45.46718901984114, lng: 9.145704845960111},
    { lat: 45.46742758743489, lng: 9.137390404214296},
    { lat: 45.46818732415618, lng: 9.129663808816524},
    { lat: 45.469646609822576, lng: 9.125135075099363},
    { lat: 45.47581495915736, lng: 9.114642098004575},
    { lat: 45.48385489360591, lng: 9.086754015476966},
    { lat: 45.487451163688455, lng: 9.076535737668177},
    { lat: 45.4892116891013, lng: 9.06597379881999},
    { lat: 45.49824586251959, lng: 9.066656114470064},
    { lat: 45.50244050705647, lng: 9.09665582279845},
    { lat: 45.522204785783444, lng: 9.092313511523402},
    { lat: 45.515749085356035, lng: 9.1223731482639},
    { lat: 45.496968937599725, lng: 9.162207506365514},
    { lat: 45.490167666957376, lng: 9.151496655555954},
    { lat: 45.485109122470995, lng: 9.143817385940338},
    { lat: 45.483687115072485, lng: 9.143059263698532},
    { lat: 45.47734035245628, lng: 9.142623392351735},
]
const CustomDot = ({ active, onClick }) => {
    return (
      <li className = "li">
        <button
          className={`custom-dot ${active ? "custom-dot--active" : ""}`}
          onClick={() => onClick()}
        />
      </li>
    );
  };

  
const comuni = [ 
    {id:'Milano', descr:'Milano'},
    {id:'Provincia di Milano', descr:'Provincia di Milano'},
    {id:'Alassio', descr:'Alassio'},
    {id:'Sanremo', descr:'Sanremo'},
];
const destinazioni =[{id:'Residenziale', descr:'Residenziale'},{id:'Commerciale', descr:'Commerciale'}];
const locali =[{id:'1', descr:'1'},{id:'2', descr:'2'},{id:'3', descr:'3'},{id:'4', descr:'4'},{id:'5', descr:'+5'}];


class BodyHome extends Component {
    constructor(props) {
        super(props);
        window.sessionStorage.setItem("zone",JSON.stringify([]));
        window.sessionStorage.setItem("destSelected",JSON.stringify([]));
        window.sessionStorage.setItem("valueImmobileFrom","");
        window.sessionStorage.setItem("valueImmobileTo","");
        window.sessionStorage.setItem("superficieFrom","");
        window.sessionStorage.setItem("superficieTo","");
        window.sessionStorage.setItem("polymap",JSON.stringify([]));
        window.sessionStorage.setItem("locali",JSON.stringify([]));
        this.state={
            zone: false,
           Immobili:[],
           btnVenditaAttivo: true,
           btnLocazioneAttivo: false,

           listPolyMap: [
            {id: 'ALL',coordinates:null,descr:'Seleziona tutti',checked:false,clicked:false},
            {id:'A', coordinates:'centroStorico', descr:'Centro storico', checked:false, clicked:false},
            {id:'B', coordinates:'Navigli', descr:'Solari, Navigli, Bocconi, Quadronno, Romana', checked:false, clicked:false},
            {id:'C', coordinates:'WashingtonFiera', descr:'Washington, Fiera, Pagano, Sempione, San Vittore', checked:false, clicked:false},
            {id:'D', coordinates:'Sempione', descr:'Sempione, Cenisio, Sarpi, Arena', checked:false, clicked:false},
            {id:'E', coordinates:'Loreto', descr:'Loreto, Centrale, Repubblica, Porta Nuova, Isola, Zara', checked:false, clicked:false},
            {id:'F', coordinates:'Montenero', descr:'Montenero, Libia, Indipendenza, XXII Marzo, V Giornate', checked:false, clicked:false},
            {id:'G', coordinates:'Lodi', descr:'Lodi, Corvetto, Brenta, Rogoredo, Ripamonti, Cermenate, Missaglia', checked:false, clicked:false},
            {id:'H', coordinates:'Famagosta', descr:'Famagosta, Barona, Boffalora', checked:false, clicked:false},
            {id:'I', coordinates:'Lorenteggio', descr:'Lorenteggio, Bande Nere, Soderini, Forze Armate, Giambellino, Baggio', checked:false, clicked:false},
            {id:'L', coordinates:'Trenno', descr:'Trenno, San Siro, Monte Stella, Lotto, Certosa, Gallarate, Varesina', checked:false, clicked:false},
            {id:'M', coordinates:'Bovisa', descr:'Bovisa, Maciachini, Affori, Zara, Niguarda, Bicocca', checked:false, clicked:false},
            {id:'N', coordinates:'Pirelli', descr:'Quartiere Giornalisti, Greco Pirelli, Monza, Turro, Padova', checked:false, clicked:false},
            {id:'O', coordinates:'Lambrate', descr:'Città studi, Lambrate, Pacini, Ortica', checked:false, clicked:false},
            {id:'P', coordinates:'Susa', descr:'Susa, Argonne, Corsica, Martini, Cuoco, Mecenate', checked:false, clicked:false},
        ],
        valueValoreImmobile: [200000, 200000],
        valueSuperficie: [60, 500],
        showMapCity: false,
        citySelcted:[],
        destinazioneSelected:[],
        localiSelected:[],
        emptyValue:[],
        valueImmobileFrom:'',
        valueImmobileTo:'',
        valueSuperficieFrom:'',
        valueSuperficieTo:'',
        valuesSlider: [0, 0],
        valuesSuperfcie: [0, 0],
        typeSearchSelected: 'Vendita'
        };
        // const dataLogin = { Username: DATA_LOGIN.username, Password:DATA_LOGIN.password };
        // axios.post('http://apifilcasaagency.ingage.eu/Login', dataLogin)
        // .then(res => {
        //     bodyExport.Token = res.data.Token;
        //     var uri ="http://apifilcasaagency.ingage.eu/Zone"; 

        //     axios.post(uri, bodyExport)   
        //     .then(res => {
        //         if(res.data.ElencoZone){
        //             this.setState({Immobili: res.data.Immobili});
        //         }
        //     })
        //     .catch((error) => {
        //         console.log(error.config);
        //     });
        // }) 
        // .catch((error) => {
        //     console.log(error.config);
        // });
        window.sessionStorage.setItem("polymap",JSON.stringify(this.state.listPolyMap));
      }
      
      onMouseOver(polygon, isClick){
        let index = -1;
        let polyMap =[...this.state.listPolyMap];
        var all = false;
        for (var i = 0; i < polyMap.length; i++) {
            if(polyMap[i].id == polygon){
                index = i;
                if(polygon === 'ALL')
                    break;
                if(polyMap[i].clicked && !isClick){
                    break;
                }
                
                
                if(isClick){
                    polyMap[i].checked = !polyMap[i].clicked;
                    polyMap[i].clicked = !polyMap[i].clicked;
                    break;
                }
                
                polyMap[i].checked = !polyMap[i].checked && !polyMap[i].clicked;
                //polyMap[i].clicked = false;
                break;
            }
        }
        if(index >= 0){
            this.setState({polyMap});
        }
        if(polygon === 'ALL')
            {
            if(polyMap[0].checked === false){
                all = true;
            }
            for(var i = 0; i < polyMap.length; i++)
                {
                   if(all)
                        {
                        polyMap[i].checked = true;
                        polyMap[i].clicked = true;     
                        }
                    else{
                        polyMap[i].checked = false;
                        polyMap[i].clicked = false;
                    }       
                }
        
        }
      }

      onMouseOver1(polygon, isClick){
        let index = -1;
        let polyMap =[...this.state.listPolyMap];
        var all = false;
        for (var i = 0; i < polyMap.length; i++) {
            if(polyMap[i].id == polygon){
                index = i;
                if(polygon === 'ALL')
                    break;
                if(polyMap[i].clicked && !isClick){
                    break;
                }
                
                polyMap[i].checked = polyMap[i].clicked;
                //polyMap[i].clicked = false;
                break;
            }
        }
        if(index >= 0){
            this.setState({polyMap});
        }
        if(polygon === 'ALL')
            {
            if(polyMap[0].checked === false){
                all = true;
            }
            for(var i = 0; i < polyMap.length; i++)
                {
                   if(all)
                        {
                        polyMap[i].checked = true;
                        polyMap[i].clicked = true;     
                        }
                    else{
                        polyMap[i].checked = false;
                        polyMap[i].clicked = false;
                    }       
                }
        }
      }

    listZone = [
        {id:'ALL', descr:'Seleziona Tutti', checked:false},
        {id:'A', descr:'Centro storico', checked:false},
        {id:'B', descr:'Solari, Navigli, Bocconi, Quadronno, Romana', checked:false},
        {id:'C', descr:'Washington, Fiera, Pagano, Sempione, San Vittore', checked:false},
        {id:'D', descr:'Sempione, Cenisio, Sarpi, Arena', checked:false},
        {id:'E', descr:'Loreto, Centrale, Repubblica, Porta Nuova, Isola, Zara', checked:false},
        {id:'F', descr:'Montenero, Libia, Indipendenza, XXII Marzo, V Giornate', checked:false},
        {id:'G', descr:'Lodi, Corvetto, Brenta, Rogoredo, Ripamonti, Cermenate, Missaglia', checked:false},
        {id:'H', descr:'Famagosta, Barona, Boffalora', checked:false},
        {id:'I', descr:'Lorenteggio, Bande Nere, Soderini, Forze Armate, Giambellino, Baggio', checked:false},
        {id:'L', descr:'Trenno, San Siro, Monte Stella, Lotto, Certosa, Gallarate, Varesina', checked:false},
        {id:'M', descr:'Bovisa, Maciachini, Affori, Zara, Niguarda, Bicocca', checked:false},
        {id:'N', descr:'Quartiere Giornalisti, Greco Pirelli, Monza, Turro, Padova', checked:false},
        {id:'O', descr:'Città studi, Lambrate, Pacini, Ortica', checked:false},
        {id:'P', descr:'Susa, Argonne, Corsica, Martini, Cuoco, Mecenate', checked:false},
    ];

    responsiveStyleMap = {};

    componentDidMount() {
        this.search();
        if(window.innerWidth > 1535){
            this.responsiveStyleMap = mapContainerStyle1920;
        }
        if(window.innerWidth > 1023 && window.innerWidth < 1535){
            this.responsiveStyleMap = mapContainerStyle;
        }
        // {(window.innerWidth > 993 && window.innerWidth < 1920) ? mapContainerStyle : mapContainerStyle1920}
    }

    setTypeSearch(typeSearch){
        this.setState({typeSearchSelected:typeSearch});
        this.search();
    }

    onchangeSelZone(zoneIdSel){
        this.setState({ polySusa:{fillColor:'#E3F5ED', fillOpacity:.5, strokeColor:'#91C3A3', zIndex: 2}});
        for (var i = 0; i < this.listZone.length; i++) {
            if(this.listZone[i].id == zoneIdSel){
                this.listZone[i].checked = !this.listZone[i].checked;
                break;
            }
        } 
    }
    
    search(){
        // this.props.history.push({
        //     pathname: '/page',
        //       state: this.state // your data array of objects
        //   });
       // this.props.prova = 'prova';
        const dataLogin = { Username: DATA_LOGIN.username, Password:DATA_LOGIN.password };
        axios.post('https://apifilcasaagency.ingage.eu/Login', dataLogin)
        .then(res => {
            bodyExport.Tipologia = this.state.typeSearchSelected;
            
            let comuni = [...this.state.citySelcted];
            let polyMap =[...this.state.listPolyMap];
            for (var i = 0; i < polyMap.length; i++) {
                if(polyMap[i].checked){
                    if(polyMap[i].id !== 'ALL')
                        comuni.push(polyMap[i].id);
                }
            }
            bodyExport.Comune = comuni;
            bodyExport.Destinazione = this.state.destinazioneSelected;
            bodyExport.Locali = this.state.localiSelected;
            if(this.state.valueImmobileFrom !== null && this.state.valueImmobileFrom !== '')
                {
                    bodyExport.ValoreImmobileDa = '' + parseInt(this.state.valueImmobileFrom);
                }
            else {bodyExport.ValoreImmobileDa = ''; }
            if(this.state.valueImmobileTo !== null && this.state.valueImmobileTo !== '')
                {
                    bodyExport.ValoreImmobileA = '' + parseInt(this.state.valueImmobileTo);
                }
            else {bodyExport.ValoreImmobileA = ''; }
            if(this.state.valueSuperficieFrom !== null && this.state.valueSuperficieFrom !== '')
                {
                    bodyExport.SuperficieDa = '' + parseInt(this.state.valueSuperficieFrom);
                }
            else {bodyExport.SuperficieDa = ''; }
            if(this.state.valueSuperficieTo !== null && this.state.valueSuperficieTo !== '')
                {
                    bodyExport.SuperficieA = '' + parseInt(this.state.valueSuperficieTo);
                }
            else {bodyExport.SuperficieA = ''; }
            bodyExport.ValoreImmobileDa = bodyExport.ValoreImmobileDa ? bodyExport.ValoreImmobileDa : null;
            bodyExport.ValoreImmobileA = bodyExport.ValoreImmobileA ? bodyExport.ValoreImmobileA : null;
            bodyExport.SuperficieDa = bodyExport.SuperficieDa ? bodyExport.SuperficieDa : null;
            bodyExport.SuperficieA = bodyExport.SuperficieA ? bodyExport.SuperficieA : null;
            bodyExport.Token = res.data.Token;
            var uri ="https://apifilcasaagency.ingage.eu/Ricerca"; 
            if(comuni.length === 0 && this.state.destinazioneSelected.length === 0 && this.state.localiSelected.length === 0
                && this.state.valueImmobileTo === "" && this.state.valueImmobileFrom === ""
                && this.state.valueSuperficieFrom === "" && this.state.valueSuperficieTo === ""
                )
                {
                    bodyExport.TIPOLOGIA = "Vendita";
                    bodyExport.NUMERO = 5;
                    
                    uri = 'https://apifilcasaagency.ingage.eu/Ultimi';
                }
                else{
                    
                }

            axios.post(uri, bodyExport)   
            .then(res => {
                if(res.data.Immobili){
                    this.setState({Immobili: res.data.Immobili});
                }
            })
            .catch((error) => {
                console.log(error.config);
            });
        }) 
        .catch((error) => {
            console.log(error.config);
        });
    }

    onchangeDropVal = event => {
        const value = event;
        console.log(value);
        switch(event.idSel){
            case 'comune':
                this.setState({citySelcted:[...this.state.citySelcted, event.value]});
                break;
            case 'destinazione':
                this.setState({destinazioneSelected:[...this.state.destinazioneSelected, event.value]});
                break;
            case 'locali':
                this.setState({localiSelected:[...this.state.localiSelected, parseInt(event.value)]});
                break;
        }
    };

    openZone = event => {
        this.setState({zone:true});
    }
    closeZone = event => {
        this.setState({zone:false});
    }


    showMap = event =>{
        if(event === 'Milano'){
            this.setState({showMapCity:!this.state.showMapCity});
        }
    }
    hideMap = event =>{
        var selzone = false;
        for (var i = 0; i < this.state.listPolyMap.length; i++) {
            if (this.state.listPolyMap[i].checked === true) {
                selzone = true;
            }
        }
        var tmp = this.state.citySelcted;
        var index = -1;
        for (var j = 0; j < tmp.length; j++) {
            if (tmp[j] === 'Milano') {
                index = j;
                break;
            }
        }
        if (index === -1 && selzone) {
            tmp.push('Milano');
        }
        if (index !== -1 && !selzone) {
            tmp.splice(index, 1);
        }
        this.setState({ citySelcted: tmp });
        window.sessionStorage.setItem("zone", JSON.stringify(this.state.citySelcted));
        window.sessionStorage.setItem("polymap", JSON.stringify(this.state.listPolyMap));
        this.setState({ showMapCity: !this.state.showMapCity });
    }
    handleChange = (event) => {
        const { target: { value } } = event;
        this.setState({citySelcted: typeof value === 'string' ? value.split(',') : value});
        window.sessionStorage.setItem("zone",JSON.stringify(value));
      };

    handleChangeDestinazione = (event) => {
        const { target: { value } } = event;
        this.setState({destinazioneSelected: typeof value === 'string' ? value.split(',') : value});
        window.sessionStorage.setItem("destSelected",JSON.stringify(value));
    };

    handleChangeLocale = (event) => {
        const { target: { value } } = event;
        this.setState({localiSelected: typeof value === 'string' ? value.split(',') : value});
        window.sessionStorage.setItem("locali",JSON.stringify(value));
    };
    onchangeSlider = (event, newValue) =>{
        this.setState({valuesSlider: newValue});
    }
    onchangeSuperficie = (event, newValue) =>{
        this.setState({valuesSuperfcie: newValue});
        window.sessionStorage.setItem("valuesSuperficie",newValue);
    }
    onInputchange = (event) => {
        this.setState({valueImmobileFrom: event.target.value});
        window.sessionStorage.setItem("valueImmobileFrom", event.target.value);
    }
    onInputchangeImmobileTo = (event) => {
        this.setState({valueImmobileTo: event.target.value});
        window.sessionStorage.setItem("valueImmobileTo", event.target.value);
    }
    onInputchangeSuperficieFrom = (event) => {
        this.setState({valueSuperficieFrom: event.target.value});
        window.sessionStorage.setItem("superficieFrom", event.target.value);
    }
    onInputchangeSuperficieTo = (event) => {
        this.setState({valueSuperficieTo: event.target.value});
        window.sessionStorage.setItem("superficieTo", event.target.value);
    }

    

    render() {
        return (
            <>
            <div className='gradientHome'>
                <div className='containerBgHome'>
                    <div className='boxTitleHome'>
                        <h1 className='titleHome'><b>Immobili selezionati</b> <br/>a Milano e dintorni</h1>
                        <h1 className='subtitleHome'>Cerca la soluzione che risponde ai tuoi desideri <br/>tra le proposte firmate Filcasa.</h1>
                    </div>
                </div>
            </div>
            <Container fluid>
            <div className='searchContainer'>
                    <Col xs={12} sm={12} md={10} lg={10} style={{margin:'auto'}}>
                        <div className='searchBox' style={{borderBottom: "1px solid #e0e0e0"}}>
                        <Row className='searchHeader' style={{boxShadow: '0px 0px 34px #00000029'}}>
                                <Col xs={10} sm={11} md={11} lg={10} style={{display: "flex",justifyContent: "flex-start", background: "none", padding: 0}}>
                                    <Button className={this.state.typeSearchSelected == 'Vendita' ? 'btn-search-selected btnSelHome': 'btn-search-not-selected btnSelHome'} onClick={() => this.setTypeSearch('Vendita')}>VENDITA</Button>
                                    <Button className={this.state.typeSearchSelected == 'Locazione' ? 'btn-search-selected btnSelHome': 'btn-search-not-selected btnSelHome'} onClick={() =>  this.setTypeSearch('Locazione')}>LOCAZIONE</Button>
                                </Col>
                                <Col className='containerlabel' xs={12} sm={12} md={12} lg={12} style={{backgroundColor: "#ffffff", display:'flex', padding:'0px', zIndex:1}}>
                                    <Col xs={12} sm={11} md={11} lg={11}>
                                        <Row style={{padding: 20}}>
                                            <Col xs={12} sm={3} md={5} lg={3}>
                                                <Form>
                                                    <Form.Group className="mb-3" controlId="co">
                                                        <h4 className='labelSelect'>Comune</h4>
                                                        <Select
                                                            className={this.state.citySelcted.length > 0 ? 'selectFilter':'selectFilterEmpty'}
                                                            multiple
                                                            displayEmpty
                                                            value={this.state.citySelcted}
                                                            onChange={this.handleChange}
                                                            renderValue={(selected) => {
                                                                if (selected.length === 0) {
                                                                return <em>Seleziona</em>;
                                                                }
                                                                return selected.join(', ');
                                                            }}
                                                            inputProps={{ 'aria-label': 'Without label' }}
                                                            >
                                                            {comuni.map((name) => (
                                                                <MenuItem className='itemSelect' key={name.id} value={name.descr} onClick={() => this.showMap(name.id)}>
                                                                    <Checkbox checked={this.state.citySelcted.indexOf(name.id) > -1} className="checkboxList"
                                                                    icon={<CircleRoundedIcon />}
                                                                    checkedIcon={<CheckCircleIcon style={{color:'#00853F'}}/>}
                                                                     />{name.descr}
                                                                     
                                                                 
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </Form.Group>
                                                </Form>
                                            </Col>
                                            <Col xs={12} sm={3} md={5} lg={3}>
                                                <Form>
                                                    <Form.Group className="mb-3" controlId="co">
                                                        <h4 className='labelSelect'>Destinazione</h4>
                                                        <Select
                                                            className={this.state.destinazioneSelected.length > 0 ? 'selectFilter':'selectFilterEmpty'}
                                                            multiple
                                                            displayEmpty
                                                            value={this.state.destinazioneSelected}
                                                            onChange={this.handleChangeDestinazione}
                                                            renderValue={(selected) => {
                                                                if (selected.length === 0) {
                                                                return <em>Seleziona</em>;
                                                                }
                                                                return selected.join(', ');
                                                            }}
                                                            inputProps={{ 'aria-label': 'Without label' }}
                                                            >
                                                            {destinazioni.map((destinazione) => (
                                                                <MenuItem className='itemSelect' key={destinazione.id} value={destinazione.descr}>
                                                                    <Checkbox checked={this.state.destinazioneSelected.indexOf(destinazione.id) > -1} className="checkboxList"
                                                                        icon={<CircleRoundedIcon />}
                                                                        checkedIcon={<CheckCircleIcon style={{color:'#00853F'}}/>}
                                                                     />{destinazione.descr}</MenuItem>
                                                            ))}
                                                        </Select>
                                                    </Form.Group>
                                                </Form>
                                            </Col>
                                            <Col xs={12} sm={3} md={5} lg={3}>
                                                <Form>
                                                    <Form.Group className="mb-3" controlId="co">
                                                    <h4 className='labelSelect'>Valore immobile</h4>
                                                        <Select
                                                            className={(this.state.valueImmobileFrom != '' || this.state.valueImmobileTo != '') ? 'selectFilter':'selectFilterEmpty'}
                                                            multiple
                                                            value={this.state.emptyValue}
                                                            displayEmpty
                                                            renderValue={() => {
                                                                if(this.state.valueImmobileFrom == '' && this.state.valueImmobileTo == ''){
                                                                    return <em>Da - a</em>;
                                                                }
                                                                return <em>{(this.state.valueImmobileFrom) ? parseInt(this.state.valueImmobileFrom).toLocaleString(): '0'}  {(this.state.valueImmobileTo) ? " - " + parseInt(this.state.valueImmobileTo).toLocaleString(): ''} €</em>
                                                            }}
                                                            inputProps={{ 'aria-label': 'Without label' }}>
                                                                <div className="containerSlider">
                                                                    <div className="rowPrices">
                                                                        <span className="prices pricesSx">min</span>
                                                                        {/* <div className="input-box"> */}
                                                                            {/* <span className="prefix">€</span> */}
                                                                            <input className='inputSliderFilter' type="number" placeholder='€'
                                                                            value={this.state.valueImmobileFrom}
                                                                            onChange={this.onInputchange}
                                                                            />
                                                                        {/* </div> */}
                                                                    </div>
                                                                    <div className="rowPrices">
                                                                        <span className="prices pricesSx">max</span>
                                                                        {/* <div className="input-box"> */}
                                                                            {/* <span className="prefix">€</span> */}
                                                                            <input className='inputSliderFilter' type="number" placeholder='€'
                                                                            value={this.state.valueImmobileTo}
                                                                            onChange={this.onInputchangeImmobileTo}
                                                                            />
                                                                        {/* </div> */}
                                                                    </div>
                                                            </div>
                                                        </Select>
                                                    </Form.Group>
                                                </Form>
                                            </Col>
                                            <Col xs={8} sm={2} md={5} lg={2}>
                                                <Form>
                                                    <Form.Group className="mb-3" controlId="co">
                                                        <h4 className='labelSelect'>Superficie</h4>
                                                        {/* <Select
                                                            className={(this.state.valuesSuperfcie[0] != 0 || this.state.valuesSuperfcie[1] != 0) ? 'selectFilter':'selectFilterEmpty'}
                                                            displayEmpty
                                                            renderValue={() => {
                                                                if(this.state.valuesSuperfcie[0] == 0 && this.state.valuesSuperfcie[1] == 0){
                                                                    return <em>Da - a</em>;
                                                                }
                                                                return <em>{this.state.valuesSuperfcie[0]} - {this.state.valuesSuperfcie[1]} mq</em>
                                                            }}
                                                                inputProps={{ 'aria-label': 'Without label' }}>
                                                                    <div className="containerSlider">
                                                                        <div className="rowPrices">
                                                                            <div className="prices pricesSx">{this.state.valuesSuperfcie[0]} mq</div>
                                                                            <div className="prices pricesDx">{this.state.valuesSuperfcie[1]} mq</div>
                                                                        </div>
                                                                        <div className="containerSliderComponent">
                                                                            <Slider
                                                                                min={60} max={400}
                                                                                step={20}
                                                                                getAriaLabel={() => 'Superficie'}
                                                                                value={this.state.valuesSuperfcie}
                                                                                onChange={this.onchangeSuperficie}
                                                                                sx={{color: '#00853F'}}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                            </Select> */}
                                                            <Select
                                                            className={(this.state.valueSuperficieFrom != 0 || this.state.valueSuperficieTo != 0) ? 'selectFilter':'selectFilterEmpty'}
                                                            multiple
                                                            value={this.state.emptyValue}
                                                            displayEmpty
                                                            renderValue={() => {
                                                                if(this.state.valueSuperficieFrom == 0 && this.state.valueSuperficieTo == 0){
                                                                    return <em>Da - a</em>;
                                                                }
                                                                // return <em>{(parseInt(this.state.valueSuperficieFrom)).toLocaleString()} - {(parseInt(this.state.valueSuperficieTo)).toLocaleString()} mq</em>
                                                                return <em>{(this.state.valueSuperficieFrom) ? parseInt(this.state.valueSuperficieFrom).toLocaleString(): '0'}  {(this.state.valueSuperficieTo) ? " - " + parseInt(this.state.valueSuperficieTo).toLocaleString(): ''} mq</em>
                                                            }}
                                                            inputProps={{ 'aria-label': 'Without label' }}>
                                                                <div className="containerSlider">
                                                                    <div className="rowPrices">
                                                                        <span className="prices pricesSx">min</span>
                                                                        <input className='inputSliderFilter' type="number" placeholder='mq'
                                                                            value={this.state.valueSuperficieFrom}
                                                                            onChange={this.onInputchangeSuperficieFrom}
                                                                        />
                                                                    </div>
                                                                    <div className="rowPrices">
                                                                        <span className="prices pricesSx">max</span>
                                                                        <input className='inputSliderFilter' type="number" placeholder='mq'
                                                                            value={this.state.valueSuperficieTo}
                                                                            onChange={this.onInputchangeSuperficieTo}
                                                                        />
                                                                    </div>
                                                            </div>
                                                        </Select>
                                                        </Form.Group>
                                                </Form>
                                            </Col>
                                            <Col className="input-locali" xs={3} sm={1} md={2} lg={1}>
                                                <Form>
                                                    <Form.Group className="mb-3" controlId="co">
                                                        <h4 className='labelSelect'>Locali</h4>
                                                        <Select
                                                            className={this.state.localiSelected.length > 0 ? 'selectFilter':'selectFilterEmpty'}
                                                            multiple
                                                            displayEmpty
                                                            value={this.state.localiSelected}
                                                            onChange={this.handleChangeLocale}
                                                            input={<OutlinedInput />}
                                                            renderValue={(selected) => {
                                                                if (selected.length === 0) {
                                                                return <em>N.</em>;
                                                                }
                                                                return selected.join(', ');
                                                            }}
                                                            inputProps={{ 'aria-label': 'Without label' }}
                                                            >
                                                            {locali.map((locale) => (
                                                                <MenuItem className='itemSelect' key={locale.id} value={locale.id}>
                                                                    <Checkbox checked={this.state.localiSelected.indexOf(locale.id) > -1} className="checkboxList"
                                                                        icon={<CircleRoundedIcon />}
                                                                        checkedIcon={<CheckCircleIcon style={{color:'#00853F'}}/>}
                                                                     />
                                                                {locale.descr}</MenuItem>
                                                            ))}
                                                        </Select>
                                                    </Form.Group>
                                                </Form>
                                            </Col>
                                            <Col xs={12} sm={1} md={1} lg={1}  className="searchMobile">
                                            <Link className='btnSearch btnSearchMobile' to={{
                                            pathname: '/' + this.state.typeSearchSelected + '-immobili',
                                                }}>
                                                <Button className='btnSearch btnSearchMobile' onClick={() => this.search()}>
                                                    <div className='iconSearch'></div>
                                                </Button>
                                            </Link>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={1} sm={1} md={2} lg={1} style={{padding:'0px'}} className="searchDesk">
                                    <Link className='btnSearch' to={{
                                            pathname: '/' + this.state.typeSearchSelected + '-immobili',
                                                }}>
                                        <Button className='btnSearch' onClick={() => this.search()}>
                                            <div className='iconSearch'></div>
                                            <span className='labelSearch'>CERCA</span>
                                        </Button>
                                        </Link>
                                    </Col>
                                </Col>
                            </Row>
                        </div>            
                    </Col> 
                </div>
                {this.state.showMapCity &&
                            
                            <div className='dialogMap'>
                                <div className="map-desktop" style={{display:'flex'}}>
                                    <div className='containerMap'>
                                        <Row>
                                            <Col xs={12} sm={12} md={7} lg={7} className='colGoogleMap'>
                                                <LoadScript googleMapsApiKey="AIzaSyAk3K2wgZc3M7n3RWSqWnIv0u5fM1n7HWc">
                                                    <GoogleMap
                                                        id="mapMilano"
                                                        // mapContainerStyle={(window.innerWidth > 993 && window.innerWidth < 1920) ? mapContainerStyle : mapContainerStyle1920}
                                                        mapContainerStyle={this.responsiveStyleMap}
                                                        zoom={11.9}
                                                        center={center}
                                                        options={{
                                                            styles: gMapStyle,
                                                            fullscreenControl: false,
                                                            zoomControl: false,
                                                            streetViewControl: false,
                                                            mapTypeControl: false
                                                            }}
                                                        >
                                                        {this.state.listPolyMap.map((poly,i) => (  
                                                            <Polygon
                                                                key={i}
                                                                paths={eval(poly.coordinates)}
                                                                options={poly.checked ? {fillColor:'#E3F5ED',fillOpacity:.5, strokeColor:'#91C3A3', zIndex: 2}:{fillColor:'transparent', fillOpacity:0, strokeColor:'#707070', zIndex: 1}}
                                                                onMouseOver={e => this.onMouseOver(poly.id, false)}
                                                                onMouseOut={e => this.onMouseOver1(poly.id, false)}
                                                                onClick={e => this.onMouseOver(poly.id, true)}
                                                            />
                                                        ))}
                                                    </GoogleMap>
                                                </LoadScript>
                                            </Col>
                                            <Col xs={12} sm={12} md={5} lg={5}>
                                                <div className='containerSelMap'>
                                                    <div className='boxSelMap'>
                                                        <div className='containerHeaderListDistrict'>
                                                            <div className='tileSelMap'>SELEZIONA UNA O PIÙ ZONE­­­</div>
                                                            <div className='separatorMap'></div>
                                                        </div>
                                                        <div className='containerListDistrict'>
                                                            {this.state.listPolyMap.map((poly,i) => (  
                                                                <div key={i} className={poly.checked ? 'containerZone containerZoneSel':'containerZone'}>
                                                                    <div className="round">
                                                                        <input type="checkbox" id={"checkbox" + poly.id} checked={poly.checked} onChange={e => this.onMouseOver(poly.id, true)} />
                                                                        <label htmlFor={"checkbox" + poly.id}></label>
                                                                    </div>
                                                                    <div htmlFor={"checkbox" + poly.id} className='titleZone'>{poly.descr}</div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                        <div className='ContmainBtnSelMap'>
                                                        <Button variant="primary" type="submit" className='mainBtn btnConfirmDistrict' onClick={() => this.hideMap()}>
                                                            <div className='contMinBtn contMainBtnDistrict'>
                                                                <div className='textMainBtn'>Conferma e procedi</div>
                                                                <div className='iconMainButton iconBtnProced'></div>
                                                            </div>
                                                        </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    {/* <div className='btnCloseMap'> */}
                                    <Button className='btnCloseMap'><div className = 'btnCloseMapText' onClick={() => this.hideMap()}>X</div>
                                    </Button>                     
                                    {/* </div> */}
                                </div>

                                {/* map mobile*/}

                                <div className="map-mobile">
                                    <div className='containerMap-mobile'>
                                        <Row className='row-map-mobile'>
                                            <Col className='colGoogleMap'>
                                                <LoadScript googleMapsApiKey="AIzaSyAk3K2wgZc3M7n3RWSqWnIv0u5fM1n7HWc">
                                                    <GoogleMap
                                                        id="mapMilano-mobile"
                                                        // mapContainerStyle={(window.innerWidth > 993 && window.innerWidth < 1920) ? mapContainerStyle : mapContainerStyle1920}
                                                        mapContainerStyle={this.responsiveStyleMap}
                                                        zoom={11.0}
                                                        center={center}
                                                        options={{
                                                            styles: gMapStyle,
                                                            fullscreenControl: false,
                                                            zoomControl: false,
                                                            streetViewControl: false,
                                                            mapTypeControl: false
                                                            }}
                                                        >
                                                        {this.state.listPolyMap.map((poly,i) => (  
                                                            <Polygon
                                                                key={i}
                                                                paths={eval(poly.coordinates)}
                                                                options={poly.checked ? {fillColor:'#E3F5ED',fillOpacity:.5, strokeColor:'#91C3A3', zIndex: 2}:{fillColor:'transparent', fillOpacity:0, strokeColor:'#707070', zIndex: 1}}
                                                                onMouseOver={e => this.onMouseOver(poly.id, false)}
                                                                onMouseOut={e => this.onMouseOver1(poly.id, false)}
                                                                onClick={e => this.onMouseOver(poly.id, true)}
                                                            />
                                                        ))}
                                                    </GoogleMap>
                                                </LoadScript>
                                            </Col>

                                            <Button className='btnCloseMap-mobile'><div className = 'btnCloseMapText-mobile' onClick={() => this.hideMap()}>CONFERMA E PROSEGUI</div></Button>
                                            <div className="btn-zone" onClick={() => this.openZone()}></div> 
                                        </Row>

                                        <Row>
                                            <Col className={this.state.zone ? 'col-zona col-zona-open' : 'col-zona col-zona-closed'} >
                                                <div className='containerSelMap-mobile'>
                                                    <div className='boxSelMap-mobile'>
                                                        <div className='containerHeaderListDistrict-mobile'>
                                                            <div className='tileSelMap-mobile'>SELEZIONA UNA O PIÙ ZONE­­­</div>
                                                            <FontAwesomeIcon className="close-zone" icon={faClose} onClick={() => this.closeZone()}/>
                                                        </div>
                                                        <div className='separatorMap-mobile'></div>
                                                        <div className='containerListDistrict-mobile'>
                                                            {this.state.listPolyMap.map((poly,i) => (  
                                                                <div key={i} className={poly.checked ? 'containerZone containerZoneSel':'containerZone'}>
                                                                    <div className="round">
                                                                        <input type="checkbox" id={"checkbox" + poly.id} checked={poly.checked} onChange={e => this.onMouseOver(poly.id, true)} />
                                                                        <label htmlFor={"checkbox" + poly.id}></label>
                                                                    </div>
                                                                    <div htmlFor={"checkbox" + poly.id} className='titleZone'>{poly.descr}</div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                            <div className={this.state.zone ? 'gradient-zone d-block' : 'gradient-zone d-none'}>
                                                <div className = 'btnCloseMapText-mobile close-zone-mobile' onClick={() => this.closeZone()}></div>
                                            </div>
                                            
                                        </Row>
                                    </div>
                                    {/* <div className='btnCloseMap'> */}
                                                         
                                    {/* </div> */}
                                </div>
                            </div>
                        }
                    
                        <div className='containerBoxCarousel'>
                        {this.state.Immobili.length>0
                        ?
                        <Row className='rowSliderApp'>
                            <Col xs={12} sm={4} md={4} lg={4} className="colFirstApp">
                                <div className='containerPres'>
                                    <h2 className="title-block">La scelta di <b>Filcasa Agency</b></h2>
                                    <h4 className="text-block"> Per trovare la casa che desideri, mettiamo a disposizione una nostra accurata selezione di immobili in vendita e affitto e la professionalità del nostro team.</h4>
                                </div>
                            </Col>
                            <Col xs={12} sm={8} md={8} lg={8}>
                                <Carousel partialVisible={true} 
                                        responsive={responsive}
                                        infinite={true}
                                        swipeable={true}
                                        autoPlay={false}
                                        showDots
                                        customDot={<CustomDot />}
                                        >
                                    {   this.state.Immobili &&
                                        this.state.Immobili.map((immobile,i) => (
                                            <Link className='glCardAppartment' key={i} to={'/vendita-immobili/' + immobile.Id_Immobile} >
                                                <Card key={i} className="genCardComp" >
                                                    <Card.Img variant="top" src={immobile?.Vetrina?.Valore}/>
                                                    <div className="box-price-card">{immobile.Prezzo.toLocaleString()} €</div>
                                                    <Card.Body>
                                                    <div className='containerCardText'>
                                                        <h3 className='titleCardApartment'>{immobile.Titolo}</h3>
                                                        <div style={{display: "flex", alignItems: "center", justifyContent: "center", marginTop: 20}}>
                                                            <div className='boxIconCardAppartment'>
                                                                <div className='imgCardMq'></div>
                                                                <p className='textIconCard'><b>{immobile.Superficie}</b> mq</p>
                                                            </div>
                                                            <div className='boxIconCardAppartment'>
                                                                <div className='imgCardLocali'></div>
                                                                <p className='textIconCard'><b>{immobile.Locali}</b> locali</p>
                                                            </div>
                                                            {immobile.Piano && (
                                                            <div className='boxIconCardAppartment'>
                                                                <div className='imgCardPiano'></div>
                                                                <p className='textIconCard'><b> piano {immobile.Piano}</b></p>
                                                            </div>
                                                            )}
                                                        </div>                                                    
                                                    </div>
                                                    </Card.Body>
                                                        <Card.Footer className='cardFooter' >
                                                            <div>
                                                                <div className='itemCardFooter' style={{float: "left"}}>
                                                                    <b><small>MOSTRA DI PIU</small></b>
                                                                </div>
                                                                <div className='itemCardFooter' style={{float: "right"}}>
                                                                    <b><IoIosArrowForward></IoIosArrowForward></b>
                                                                </div>
                                                            </div>                                        
                                                        </Card.Footer>
                                                </Card>
                                            </Link>   
                                        ))
                                    }                                
                                </Carousel>
                            </Col>
                        </Row>
                        
                        :
                        <></>
                    }   </div>
                </Container>
                <div id="attivita" className='containerBoxTextActivity'>
                    <Container fluid>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <h1 className="title-block" style={{padding:40, paddingBottom:0}}>Le <b>attività</b></h1>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <div className='containerGlobalActivity'>
                                    <div className='containerActivityLeft'>
                                        <div className='boxActivity'>
                                            <p className='textActivity'><b>Filcasa Agency S.r.l. è diretta da professionisti di consolidata esperienza del settore immobiliare che ne curano la gestione strategica e operativa.</b><br/> Attiva e presente sul mercato dal 1990, svolge attività di intermediazione e consulenza con diverse agenzie, uffici vendite e store a Milano.</p>
                                        </div>
                                    </div>
                                    <div className='containerActivityLeft'>
                                        <div className='boxActivity'>
                                            <p className='textActivity'>Negli ultimi anni si è molto rafforzata la parte di consulenza per società e fondi di gestione patrimoniale accanto alla tradizionale attività di mediazione. Grazie a un unico e affiatato gruppo vendite, ogni immobile - anche una singola unità immobiliare - viene gestita con la stessa professionalità e impegno.</p>
                                        </div>
                                    </div>
                                    <div className='containerActivityLeft'>
                                        <div className='boxActivity' style={{textAlign:'center'}}>
                                            <Link to={'/ChiSiamo'}>
                                                <Button variant="primary" className='mainBtn'>
                                                    <div className='contMinBtn'>
                                                        <div className='textMainBtn'>SCOPRI</div>
                                                    </div>
                                                </Button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                </Col>
                            </Row>
                    </Container>
                </div>

                <div className='containerListAct'>
                    <Container fluid>
                        <Row>
                            <Col xs={12} sm={9} md={9} lg={12}>
                                <Row>
                                    <Col xs={12} sm={6} md={5} lg={4} className="margin-top-card">
                                        <div style={{minWidth: 170, minHeight: 130, height: "auto", border: "1px solid #d9ece2", backgroundColor: "#ffffff", padding: 20}}>
                                            <div className='iconHomeServizi'></div>
                                            <h2 className="title-multiple-card">Servizi di intermediazione</h2>
                                            <h3 className="text-multiple-card">nelle compravendite e nelle locazioni di interi edifici o singole unità</h3>                                    
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={6} md={3} lg={3} className="margin-top-card">
                                        <div style={{minWidth: 125, minHeight: 130, height: "auto", border: "1px solid #d9ece2", backgroundColor: "#ffffff", padding: 20}}>
                                            <div className='iconHomeIndagini'></div>
                                            <h2 className="title-multiple-card">Indagini</h2>
                                            <h3 className="text-multiple-card">di mercato</h3>
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={6} md={4} lg={3} className="margin-top-card">
                                        <div style={{minWidth: 125, minHeight: 130, height: "auto", border: "1px solid #d9ece2", backgroundColor: "#ffffff", padding: 20}}>
                                            <div className='iconHomeValutazioniImmobiliari'></div>
                                            <h2 className="title-multiple-card">Valutazioni immobiliari</h2>
                                            <h3 className="text-multiple-card">consulenze e perizie</h3>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={12} sm={3} md={3} lg={3}>                            
                            </Col>
                        </Row>
                        <Row style={{marginTop: 15}}>
                            <Col xs={12} sm={9} md={9} lg={12}>
                                <Row>
                                    <Col xs={12} sm={6} md={4} lg={3} className="margin-top-card">                                  
                                        <div style={{minWidth: 125, minHeight: 130, height: "auto", border: "1px solid #d9ece2", backgroundColor: "#ffffff", padding: 20}}>
                                            <div className='iconHomeAssistenza'></div>
                                            <h2 className="title-multiple-card">Assistenza</h2>
                                            <h3 className="text-multiple-card">tecnico-legale</h3>
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={6} md={4} lg={3} className="margin-top-card">
                                        <div style={{minWidth: 125, minHeight: 130, height: "auto", border: "1px solid #d9ece2", backgroundColor: "#ffffff", padding: 20}}>
                                            <div className='iconHomeHomeStaging'></div>
                                            <h2 className="title-multiple-card">Home staging</h2>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
                
                <div className='containerInfoAgency'>
                    <div className='elementBkgInfoAgencyTop'></div>
                        <div style={{width: "100%", height: 350, backgroundColor: "#f3f3f3", zIndex: 0}}> 
                            <Container fluid style={{transform: "translateY(-50%)", zIndex: 1}}>
                                <Row>
                                <div className='boxPeopleAgency'>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <img src={ImagePeopleAgency} className='imageChisiamo'/>
                                    </Col>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <div className='containerChiSiamo'>
                                            <h1 className="title-block">La nostra <b>Agency</b></h1>
                                            <p className="text-block"><b>Guidati da un team di professionisti del settore</b> – che ne curano la gestione operativa e strategica – siamo presenti sul mercato da più di 30 anni. Ascoltiamo, consigliamo e accompagniamo i clienti nella scelta della loro casa. Abbiamo <b>esperienza e competenza sia nella parte normativa che nella parte tecnica</b>, siamo attenti alle nuove tendenze e ai nuovi stili di vita con un occhio sempre vigile sul mercato e le sue evoluzioni per soddisfare il cliente in tutte le fasi della compravendita o locazione. La struttura è formata da una <b>sede centrale</b> a cui si aggiungono diverse agenzie distribuite sul territorio milanese e Store o Infopoint con uffici vendita presso gli immobili in commercializzazione più importanti. Gli Store Filcasa sono concepiti come uno spazio – arricchito di immagini e sensazioni - dove il cliente si immerge in ogni dettaglio della sua nuova casa.</p>
                                        </div>                                            
                                    </Col>
                                </div>
                                </Row>
                            </Container>
                        </div>
                    <div className='elementBkgInfoAgencyBottom'></div>
                </div>
            </>
        )
    }
}
export default BodyHome