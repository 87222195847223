import React, { Component, PropTypes } from 'react';
import axios from 'axios';
import "./viewApartment.css";
import { Container, Row, Col, Button, Tabs, Tab, Form, Card, DropdownButton, Dropdown} from 'react-bootstrap';
import "react-multi-carousel/lib/styles.css";
import Parser from 'html-react-parser';
import { DATA_LOGIN } from '../../constants'
import { AiOutlineHome }  from "react-icons/ai";
import { BsDoorOpen } from "react-icons/bs";
import { IoIosGitCompare,IoIosArrowForward } from "react-icons/io";
import { BrowserRouter, Switch, Route, Navigate, Link } from "react-router-dom";
// import SelectBox from '../Generic/Selectbox/select-box';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Slider from '@mui/material/Slider';
import Checkbox from '@mui/material/Checkbox';
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import gMapStyle from "../../style/gMapStyle";
import { GoogleMap, useJsApiLoader, Polygon, LoadScript } from '@react-google-maps/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose } from '@fortawesome/free-solid-svg-icons'

const API_LOGIN_URL = 'https://apifilcasaagency.ingage.eu/Login';
const API_RICERCA_URL = 'https://apifilcasaagency.ingage.eu/Ricerca';
const API_ZONE_URL = 'https://apifilcasaagency.ingage.eu/Zone';

const mapContainerStyle = {
    height: "500px",
    width: "600px"
  }

  const mapContainerStyle1920 = {
    height: "600px",
    width: "800px"
  }
const emptyList = [];

const bodyExport = {
    Token: "",
    Tipologia: "Vendita",
    Comune: [],
    Destinazione: [],
    ValoreImmobileDa: null,
    ValoreImmobileA: null,
    SuperficieDa: null,
    SuperficieA: null,
    Locali: null
}
const comuni = [
    {id:'Milano', descr:'Milano'},
    {id:'Provincia di Milano', descr:'Provincia di Milano'},
    {id:'Alassio', descr:'Alassio'},
    {id:'Sanremo', descr:'Sanremo'},
];
const center = { lat: 45.459570610581904,
    lng: 9.163184669589096 }

const centroStorico = [
    { lat: 45.46147352228216, lng: 9.164215641951856},
    { lat: 45.467713629021645, lng: 9.16639274468963},
    { lat: 45.47752452365164, lng: 9.181008555851013},
    { lat: 45.48120804006813, lng: 9.182367863092164},
    { lat: 45.480065429425764, lng: 9.189559042179026},
    { lat: 45.479457235151365, lng: 9.19423020765898},
    { lat: 45.47417797623128, lng: 9.20479098502093},
    { lat: 45.473697051748196, lng: 9.205501025045432},
    { lat: 45.46713715807906, lng: 9.205869887681532},
    { lat: 45.46222603677896, lng: 9.206762090271484},
    { lat: 45.45811883036394, lng: 9.204890229232719},
    { lat: 45.45674164882762, lng: 9.204076485200188},
    { lat: 45.45231603052424, lng: 9.201983675479804},
    { lat: 45.45183382891125, lng: 9.200827472734066},
    { lat: 45.452217169133256, lng: 9.198831834672042},
    { lat: 45.45203330507182, lng: 9.196095828687675},
    { lat: 45.451985460927624, lng: 9.189932207188063},
    { lat: 45.45252942857895, lng: 9.185402062972493},
    { lat: 45.452795120473155, lng: 9.180783481067486},
    { lat: 45.453096564848245, lng: 9.179624866427307},
    { lat: 45.452887259982646, lng: 9.17724036744628},
    { lat: 45.46147352228216, lng: 9.164215641951856},
]

const WashingtonFiera = [
    { lat: 45.46147352228216, lng: 9.164215641951856},
    { lat: 45.467713629021645, lng: 9.16639274468963},
    { lat: 45.47752452365164, lng: 9.181008555851013},
    { lat: 45.48781523731526, lng: 9.156783816664975},
    { lat: 45.490167666957376, lng: 9.151496655555954},
    { lat: 45.485109122470995, lng: 9.143817385940338},
    { lat: 45.483687115072485, lng: 9.143059263698532},
    { lat: 45.47734035245628, lng: 9.142623392351735},
    { lat: 45.46587767351897, lng: 9.146180411728606},
    { lat: 45.455194473028506, lng: 9.152459596900888},
    { lat: 45.45941415157269, lng: 9.165852188152758},
    { lat: 45.46001640210163, lng: 9.166495049132504},
    { lat: 45.46147352228216, lng: 9.164215641951856},
]

const Sempione = [
    { lat: 45.47752452365164, lng: 9.181008555851013},
    { lat: 45.48781523731526, lng: 9.156783816664975},
    { lat: 45.490167666957376, lng: 9.151496655555954},
    { lat: 45.496968937599725, lng: 9.162207506365514},
    { lat: 45.48454681350399, lng: 9.180143605190764},
    { lat: 45.48120804006813, lng: 9.182367863092164},
    { lat: 45.47752452365164, lng: 9.181008555851013},
]

const Loreto = [
    { lat: 45.48120804006813, lng: 9.182367863092164},
    { lat: 45.480065429425764, lng: 9.189559042179026},
    { lat: 45.479457235151365, lng: 9.19423020765898},
    { lat: 45.47417797623128, lng: 9.20479098502093},
    { lat: 45.48581271432765, lng: 9.217025766601054},
    { lat: 45.49406986765262, lng: 9.200248581026859},
    { lat: 45.497909908940535, lng: 9.186764624211694},
    { lat: 45.49756835649549, lng: 9.184220942518381},
    { lat: 45.49036692928878, lng: 9.180995897778327},
    { lat: 45.48673822064472, lng: 9.176965724032906},
    { lat: 45.48454681350399, lng: 9.180143605190764},
]

const Navigli = [
    { lat: 45.455194473028506, lng: 9.152459596900888},
    { lat: 45.45941415157269, lng: 9.165852188152758},
    { lat: 45.46001640210163, lng: 9.166495049132504},
    { lat: 45.452887259982646, lng: 9.17724036744628},
    { lat: 45.453096564848245, lng: 9.179624866427307},
    { lat: 45.452795120473155, lng: 9.180783481067486},
    { lat: 45.45252942857895, lng: 9.185402062972493},
    { lat: 45.451985460927624, lng: 9.189932207188063},
    { lat: 45.45203330507182, lng: 9.196095828687675},
    { lat: 45.452217169133256, lng: 9.198831834672042},
    { lat: 45.45183382891125, lng: 9.200827472734066},
    { lat: 45.45231603052424, lng: 9.201983675479804},
    { lat: 45.44694353360185, lng: 9.210733632223032},
    { lat: 45.44583437428013, lng: 9.18851144133567},
    { lat: 45.44334259338251, lng: 9.179501922829065},
    { lat: 45.443931038741674, lng: 9.167137055650109},
    { lat: 45.44418907351236, lng: 9.164879696230344},
    { lat: 45.44481434153334, lng: 9.162480925331955},
    { lat: 45.445446283071306, lng: 9.160633653387727},
    { lat: 45.44666841668427, lng: 9.158563197739632},
    { lat: 45.447091719811276, lng: 9.158118860734977},
    { lat: 45.44908495167594, lng: 9.157057916155631},
    { lat: 45.450204539322144, lng: 9.15533415107287},
]

const Montenero = [
    { lat: 45.45231603052424, lng: 9.201983675479804},
    { lat: 45.45674164882762, lng: 9.204076485200188},
    { lat: 45.45811883036394, lng: 9.204890229232719},
    { lat: 45.46222603677896, lng: 9.206762090271484},
    { lat: 45.46713715807906, lng: 9.205869887681532},
    { lat: 45.473697051748196, lng: 9.205501025045432},
    { lat: 45.47417797623128, lng: 9.20479098502093},
    { lat: 45.48581271432765, lng: 9.217025766601054},
    { lat: 45.46827863005173, lng: 9.217449507985814},
    { lat: 45.468190139959205, lng: 9.223786812841537},
    { lat: 45.46217445404514, lng: 9.223869898080872},
    { lat: 45.46217322887975, lng: 9.217654884059622},
    { lat: 45.456579243306514, lng: 9.217694404995187},
    { lat: 45.44694353360185, lng: 9.210733632223032},
]

const Susa = [
    { lat: 45.468190139959205, lng: 9.223786812841537},
    { lat: 45.46217445404514, lng: 9.223869898080872},
    { lat: 45.46217322887975, lng: 9.217654884059622},
    { lat: 45.456579243306514, lng: 9.217694404995187},
    { lat: 45.44694353360185, lng: 9.210733632223032},
    { lat: 45.44583940264645, lng: 9.212942749325418},
    { lat: 45.44783555048265, lng: 9.259510820317413},
    { lat: 45.4591507339667, lng: 9.251801402772163},
    { lat: 45.4710803741923, lng: 9.252460320106524},
    { lat: 45.47125603960684, lng: 9.248543212132494},
    { lat: 45.47096169574896, lng: 9.245761856271075},
    { lat: 45.47015762140177, lng: 9.245166624932532},
    { lat: 45.46958400833077, lng: 9.241618455137749},
    { lat: 45.4697520340382, lng: 9.237287530136244},
    { lat: 45.46834477714145, lng: 9.23280138379439},
]

const Lambrate = [
    { lat: 45.48581271432765, lng: 9.217025766601054},
    { lat: 45.46827863005173, lng: 9.217449507985814},
    { lat: 45.468190139959205, lng: 9.223786812841537},
    { lat: 45.46834477714145, lng: 9.23280138379439},
    { lat: 45.4697520340382, lng: 9.237287530136244},
    { lat: 45.46958400833077, lng: 9.241618455137749},
    { lat: 45.47015762140177, lng: 9.245166624932532},
    { lat: 45.47096169574896, lng: 9.245761856271075},
    { lat: 45.47125603960684, lng: 9.248543212132494},
    { lat: 45.4710803741923, lng: 9.252460320106524},
    { lat: 45.47662102269289, lng: 9.254197257085409},
    { lat: 45.48383046687485, lng: 9.251309889275365},
    { lat: 45.49110926343957, lng: 9.252363858024207},
    { lat: 45.49613628958832, lng: 9.25548618666735},
    { lat: 45.50029558163383, lng: 9.255852584811969},
    { lat: 45.50260790821067, lng: 9.256939043272357},
    { lat: 45.5061529595337, lng: 9.249220270766035},
    { lat: 45.49310177476243, lng: 9.231869795162416},
    { lat: 45.49204163462516, lng: 9.228848501615316},
    { lat: 45.49145250498545, lng: 9.226244335606397},
    { lat: 45.48950160719311, lng: 9.223242196651611},
    { lat: 45.48732902300309, lng: 9.220887373289889},
]

const Pirelli = [
    { lat: 45.48581271432765, lng: 9.217025766601054},
    { lat: 45.49406986765262, lng: 9.200248581026859},
    { lat: 45.52617242021473, lng: 9.214599135360407},
    { lat: 45.5061529595337, lng: 9.249220270766035},
    { lat: 45.49310177476243, lng: 9.231869795162416},
    { lat: 45.49204163462516, lng: 9.228848501615316},
    { lat: 45.49145250498545, lng: 9.226244335606397},
    { lat: 45.48950160719311, lng: 9.223242196651611},
    { lat: 45.48732902300309, lng: 9.220887373289889},
]

const Bovisa = [
    { lat: 45.52617242021473, lng: 9.214599135360407},
    { lat: 45.49406986765262, lng: 9.200248581026859},
    { lat: 45.497909908940535, lng: 9.186764624211694},
    { lat: 45.49756835649549, lng: 9.184220942518381},
    { lat: 45.49036692928878, lng: 9.180995897778327},
    { lat: 45.48673822064472, lng: 9.176965724032906},
    { lat: 45.496968937599725, lng: 9.162207506365514},
    { lat: 45.515749085356035, lng: 9.1223731482639},
    { lat: 45.52205473533669, lng: 9.150911812159435},
    { lat: 45.536456198675864, lng: 9.163008380486021},
    { lat: 45.53430600142605, lng: 9.173470249270878},
    { lat: 45.532810021468244, lng: 9.193002546976194},
    { lat: 45.52850561285415, lng: 9.21008031837612},
]

const Lodi = [
    { lat: 45.44694353360185, lng: 9.210733632223032},
    { lat: 45.44583437428013, lng: 9.18851144133567},
    { lat: 45.44334259338251, lng: 9.179501922829065},
    { lat: 45.44370431568185, lng: 9.172606410085551},
    { lat: 45.437878063954216, lng: 9.17133217582161},
    { lat: 45.433241832272856, lng: 9.168378075733235},
    { lat: 45.4290481618726, lng: 9.163090839536096},
    { lat: 45.42317395629132, lng: 9.159951744675709},
    { lat: 45.418313003567256, lng: 9.154465333211595},
    { lat: 45.41473606033393, lng: 9.160803589128234},
    { lat: 45.40752668672762, lng: 9.162848671018498},
    { lat: 45.39651392280874, lng: 9.180198699390141},
    { lat: 45.38747284552316, lng: 9.188581056024692},
    { lat: 45.393388109825864, lng: 9.20345694059592},
    { lat: 45.40479399695664, lng: 9.226802054624839},
    { lat: 45.40868824057536, lng: 9.22977394219666},
    { lat: 45.4101907078822, lng: 9.240397381926538},
    { lat: 45.41831237581309, lng: 9.24709723016886},
    { lat: 45.42690302083324, lng: 9.2457503720563},
    { lat: 45.433652993889105, lng: 9.25526128615409},
    { lat: 45.44783555048265, lng: 9.259510820317413},
    { lat: 45.44583940264645, lng: 9.212942749325418},
]

const Famagosta = [
    { lat: 45.44370431568185, lng: 9.172606410085551},
    { lat: 45.437878063954216, lng: 9.17133217582161},
    { lat: 45.433241832272856, lng: 9.168378075733235},
    { lat: 45.4290481618726, lng: 9.163090839536096},
    { lat: 45.42317395629132, lng: 9.159951744675709},
    { lat: 45.418313003567256, lng: 9.154465333211595},
    { lat: 45.43527922299312, lng: 9.109707410789978},
    { lat: 45.435744906396984, lng: 9.11405924193976},
    { lat: 45.43907281936416, lng: 9.125953247974053},
    { lat: 45.44077121676301, lng: 9.128038709104175},
    { lat: 45.444015381150116, lng: 9.137645217703119},
    { lat: 45.44809760374368, lng: 9.157601254851215},
    { lat: 45.44666841668427, lng: 9.158563197739632},
    { lat: 45.445446283071306, lng: 9.160633653387727},
    { lat: 45.44481434153334, lng: 9.162480925331955},
    { lat: 45.44418907351236, lng: 9.164879696230344},
    { lat: 45.443931038741674, lng: 9.167137055650109},
]

const Lorenteggio = [
    { lat: 45.44809760374368, lng: 9.157601254851215},
    { lat: 45.444015381150116, lng: 9.137645217703119},
    { lat: 45.44077121676301, lng: 9.128038709104175},
    { lat: 45.43907281936416, lng: 9.125953247974053},
    { lat: 45.435744906396984, lng: 9.11405924193976},
    { lat: 45.43527922299312, lng: 9.109707410789978},
    { lat: 45.43942747114099, lng: 9.107272768042748},
    { lat: 45.44404668756187, lng: 9.106510875951475},
    { lat: 45.45107228068368, lng: 9.10194352667241},
    { lat: 45.45672160107742, lng: 9.07740005119664},
    { lat: 45.4638356911161, lng: 9.075671079180301},
    { lat: 45.472104437389774, lng: 9.079775032139654},
    { lat: 45.4892116891013, lng: 9.06597379881999},
    { lat: 45.487451163688455, lng: 9.076535737668177},
    { lat: 45.48385489360591, lng: 9.086754015476966},
    { lat: 45.47581495915736, lng: 9.114642098004575},
    { lat: 45.469646609822576, lng: 9.125135075099363},
    { lat: 45.46818732415618, lng: 9.129663808816524},
    { lat: 45.46742758743489, lng: 9.137390404214296},
    { lat: 45.46718901984114, lng: 9.145704845960111},
    { lat: 45.46587767351897, lng: 9.146180411728606},
    { lat: 45.455194473028506, lng: 9.152459596900888},
    { lat: 45.450204539322144, lng: 9.15533415107287},
    { lat: 45.44908495167594, lng: 9.157057916155631},
]

const Trenno = [
    { lat: 45.46718901984114, lng: 9.145704845960111},
    { lat: 45.46742758743489, lng: 9.137390404214296},
    { lat: 45.46818732415618, lng: 9.129663808816524},
    { lat: 45.469646609822576, lng: 9.125135075099363},
    { lat: 45.47581495915736, lng: 9.114642098004575},
    { lat: 45.48385489360591, lng: 9.086754015476966},
    { lat: 45.487451163688455, lng: 9.076535737668177},
    { lat: 45.4892116891013, lng: 9.06597379881999},
    { lat: 45.49824586251959, lng: 9.066656114470064},
    { lat: 45.50244050705647, lng: 9.09665582279845},
    { lat: 45.522204785783444, lng: 9.092313511523402},
    { lat: 45.515749085356035, lng: 9.1223731482639},
    { lat: 45.496968937599725, lng: 9.162207506365514},
    { lat: 45.490167666957376, lng: 9.151496655555954},
    { lat: 45.485109122470995, lng: 9.143817385940338},
    { lat: 45.483687115072485, lng: 9.143059263698532},
    { lat: 45.47734035245628, lng: 9.142623392351735},
]

const destinazioni =[{id:'Residenziale', descr:'Residenziale'},{id:'Commerciale', descr:'Commerciale'}];
const locali =[{id:'1', descr:'1'},{id:'2', descr:'2'},{id:'3', descr:'3'},{id:'4', descr:'4'},{id:'5', descr:'5+'}];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      // maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      // width: 250,
    }
  },
};


class ViewApartment extends Component {
    constructor(props) {
        super(props);

        if(window.sessionStorage.polymap === null || window.sessionStorage.polymap === undefined)
        {
           var polmap = [
                {id: 'ALL',coordinates:null,descr:'Seleziona tutti',checked:false,clicked:false},
                {id:'A', coordinates:'centroStorico', descr:'Centro storico', checked:false, clicked:false},
                {id:'B', coordinates:'Navigli', descr:'Solari, Navigli, Bocconi, Quadronno, Romana', checked:false, clicked:false},
                {id:'C', coordinates:'WashingtonFiera', descr:'Washington, Fiera, Pagano, Sempione, San Vittore', checked:false, clicked:false},
                {id:'D', coordinates:'Sempione', descr:'Sempione, Cenisio, Sarpi, Arena', checked:false, clicked:false},
                {id:'E', coordinates:'Loreto', descr:'Loreto, Centrale, Repubblica, Porta Nuova, Isola, Zara', checked:false, clicked:false},
                {id:'F', coordinates:'Montenero', descr:'Montenero, Libia, Indipendenza, XXII Marzo, V Giornate', checked:false, clicked:false},
                {id:'G', coordinates:'Lodi', descr:'Lodi, Corvetto, Brenta, Rogoredo, Ripamonti, Cermenate, Missaglia', checked:false, clicked:false},
                {id:'H', coordinates:'Famagosta', descr:'Famagosta, Barona, Boffalora', checked:false, clicked:false},
                {id:'I', coordinates:'Lorenteggio', descr:'Lorenteggio, Bande Nere, Soderini, Forze Armate, Giambellino, Baggio', checked:false, clicked:false},
                {id:'L', coordinates:'Trenno', descr:'Trenno, San Siro, Monte Stella, Lotto, Certosa, Gallarate, Varesina', checked:false, clicked:false},
                {id:'M', coordinates:'Bovisa', descr:'Bovisa, Maciachini, Affori, Zara, Niguarda, Bicocca', checked:false, clicked:false},
                {id:'N', coordinates:'Pirelli', descr:'Quartiere Giornalisti, Greco Pirelli, Monza, Turro, Padova', checked:false, clicked:false},
                {id:'O', coordinates:'Lambrate', descr:'Città studi, Lambrate, Pacini, Ortica', checked:false, clicked:false},
                {id:'P', coordinates:'Susa', descr:'Susa, Argonne, Corsica, Martini, Cuoco, Mecenate', checked:false, clicked:false},
            ]
            window.sessionStorage.setItem("polymap",JSON.stringify(polmap));
        }

        if(window.sessionStorage.zone === null || window.sessionStorage.zone === undefined ){
            window.sessionStorage.setItem("zone",JSON.stringify(emptyList));
        }
        if(window.sessionStorage.destSelected === null || window.sessionStorage.destSelected === undefined ){
            window.sessionStorage.setItem("destSelected",JSON.stringify(emptyList));
        }
        if(window.sessionStorage.locali === null || window.sessionStorage.locali === undefined ){
            window.sessionStorage.setItem("locali",JSON.stringify(emptyList));
        }
        if(window.sessionStorage.valueImmobileFrom === null || window.sessionStorage.valueImmobileFrom === undefined ){
            window.sessionStorage.setItem("valueImmobileFrom","");
        }
        if(window.sessionStorage.valueImmobileTo === null || window.sessionStorage.valueImmobileTo === undefined ){
            window.sessionStorage.setItem("valueImmobileTo","");
        }
        if(window.sessionStorage.superficieFrom === null || window.sessionStorage.superficieFrom === undefined ){
            window.sessionStorage.setItem("superficieFrom","");
        }
        if(window.sessionStorage.superficieTo === null || window.sessionStorage.superficieTo === undefined ){
            window.sessionStorage.setItem("superficieTo","");
        }

        this.state={
            apartmentList:[],
            showMapCity: false,
            valueValoreImmobile: [200000, 200000],
            valueSuperficie: [60, 500],
            showMapCity: false,
            citySelected: JSON.parse(window.sessionStorage.zone),
            destinazioneSelected: JSON.parse(window.sessionStorage.destSelected),
            localiSelected:[],
            limitTo: 20,
            comuneName:JSON.parse(window.sessionStorage.zone),
            setComuneName:[], //JSON.parse(window.sessionStorage.zone),
            destinazioneName: JSON.parse(window.sessionStorage.destSelected),
            setDestinazioneName: JSON.parse(window.sessionStorage.destSelected),
            localeName:JSON.parse(window.sessionStorage.locali),
            setLocaleName:[],
            valuesSlider: [0, 5000000],
            valuesSuperfcie: [60, 400],
            emptyValue:[],
            valueImmobileFrom:window.sessionStorage.valueImmobileFrom,
            valueImmobileTo:window.sessionStorage.valueImmobileTo,
            valueSuperficieFrom:window.sessionStorage.superficieFrom,
            valueSuperficieTo:window.sessionStorage.superficieTo,
            showSpinner:false,
            showMessage:false,
            listPolyMap: JSON.parse(window.sessionStorage.polymap),
            zone: false

        };
        this.apiToken = "";
        this.comuniFuoriMilano = [];
      }

    responsiveStyleMap = {};


    componentDidMount(){
        document.title = this.props.typeDescr.charAt(0).toUpperCase() + this.props.typeDescr.slice(1);
        // this.getLoginToken();
        this.getZone();
        this.getListAppartment();
        if(window.innerWidth > 1535){
            this.responsiveStyleMap = mapContainerStyle1920;
        }
        if(window.innerWidth > 1023 && window.innerWidth < 1535){
            this.responsiveStyleMap = mapContainerStyle;
        }
    }
    onMouseOver(polygon, isClick){
        let index = -1;
        let polyMap =[...this.state.listPolyMap];
        var all = false;
        for (var i = 0; i < polyMap.length; i++) {
            if(polyMap[i].id == polygon){
                index = i;
                if(polygon === 'ALL')
                    break;
                if(polyMap[i].clicked && !isClick){
                    break;
                }


                if(isClick){
                    polyMap[i].checked = !polyMap[i].clicked;
                    polyMap[i].clicked = !polyMap[i].clicked;
                    break;
                }

                polyMap[i].checked = !polyMap[i].checked && !polyMap[i].clicked;
                //polyMap[i].clicked = false;
                break;
            }
        }
        if(index >= 0){
            this.setState({polyMap});
        }
        if(polygon === 'ALL')
            {
            if(polyMap[0].checked === false){
                all = true;
            }
            for(var i = 0; i < polyMap.length; i++)
                {
                   if(all)
                        {
                        polyMap[i].checked = true;
                        polyMap[i].clicked = true;
                        }
                    else{
                        polyMap[i].checked = false;
                        polyMap[i].clicked = false;
                    }
                }

        }
      }

      onMouseOver1(polygon, isClick){
        let index = -1;
        let polyMap =[...this.state.listPolyMap];
        var all = false;
        for (var i = 0; i < polyMap.length; i++) {
            if(polyMap[i].id == polygon){
                index = i;
                if(polygon === 'ALL')
                    break;
                if(polyMap[i].clicked && !isClick){
                    break;
                }

                polyMap[i].checked = polyMap[i].clicked;
                //polyMap[i].clicked = false;
                break;
            }
        }
        if(index >= 0){
            this.setState({polyMap});
        }
        if(polygon === 'ALL')
            {
            if(polyMap[0].checked === false){
                all = true;
            }
            for(var i = 0; i < polyMap.length; i++)
                {
                   if(all)
                        {
                        polyMap[i].checked = true;
                        polyMap[i].clicked = true;
                        }
                    else{
                        polyMap[i].checked = false;
                        polyMap[i].clicked = false;
                    }
                }
        }
      }

    listZone = [
        {id:'ALL', descr:'Seleziona Tutti', checked:false},
        {id:'A', descr:'Centro storico', checked:false},
        {id:'B', descr:'Solari, Navigli, Bocconi, Quadronno, Romana', checked:false},
        {id:'C', descr:'Washington, Fiera, Pagano, Sempione, San Vittore', checked:false},
        {id:'D', descr:'Sempione, Cenisio, Sarpi, Arena', checked:false},
        {id:'E', descr:'Loreto, Centrale, Repubblica, Porta Nuova, Isola, Zara', checked:false},
        {id:'F', descr:'Montenero, Libia, Indipendenza, XXII Marzo, V Giornate', checked:false},
        {id:'G', descr:'Lodi, Corvetto, Brenta, Rogoredo, Ripamonti, Cermenate, Missaglia', checked:false},
        {id:'H', descr:'Famagosta, Barona, Boffalora', checked:false},
        {id:'I', descr:'Lorenteggio, Bande Nere, Soderini, Forze Armate, Giambellino, Baggio', checked:false},
        {id:'L', descr:'Trenno, San Siro, Monte Stella, Lotto, Certosa, Gallarate, Varesina', checked:false},
        {id:'M', descr:'Bovisa, Maciachini, Affori, Zara, Niguarda, Bicocca', checked:false},
        {id:'N', descr:'Quartiere Giornalisti, Greco Pirelli, Monza, Turro, Padova', checked:false},
        {id:'O', descr:'Città studi, Lambrate, Pacini, Ortica', checked:false},
        {id:'P', descr:'Susa, Argonne, Corsica, Martini, Cuoco, Mecenate', checked:false},
    ];


    async getLoginToken(){
      // params: no params
      // Richiesta all'api all'endpoint '/Login' per ottenere il token di accesso alle risorse.
      // La funzione setta un'attributo (this.apiToken) al valore del token ritornato dall'api.
      const dataLogin = { Username: DATA_LOGIN.username, Password: DATA_LOGIN.password };
      let response = await axios.post(API_LOGIN_URL, dataLogin);
      console.log(response);
      console.log(response.data.Token);
      this.apiToken = response.data.Token;

    }

    async getZone(){
      // params: no params
      // Richiesta all'api all'endpoint '/Zone' per ottenere tutte le zone utili.
      // Viene fatto il parsing del json ritornato dalla richiesta per prendere solamente i valori che
      // corrispondono a comuni fuori da Milano e che fanno parte della provincia.
      // Questi valori vengono presi, viene creato un oggetto json con questi che poi viene pushato in un
      // array che verra' usato per scopi successivi.
      await this.getLoginToken();
      const apiTokenJson = { "Token": this.apiToken };
      let response = await axios.post(API_ZONE_URL, apiTokenJson);
      console.log(response);
      console.log(this.state.comuneName);
      console.log(response.data.ElencoZone.length);
      for(var i = 0; i < response.data.ElencoZone.length; i++){
        let codiceComune = response.data.ElencoZone[i].Codice;
        let valoreComune = response.data.ElencoZone[i].Valore;
        if((codiceComune.length > 1) && (codiceComune !== 'VARESE') && (codiceComune !== 'SANREMO')){
          console.log(codiceComune);
          let comuneDaPushare = {id: codiceComune, coordinate: valoreComune, descr: valoreComune, checked: false, clicked: false};
          console.log(comuneDaPushare);
          this.comuniFuoriMilano.push(comuneDaPushare);
        }
      }
      console.log(this.comuniFuoriMilano);
    }

    async getListAppartment(){

        this.setState({ showSpinner: true });
        this.setState({ showMessage: false });

        const dataLogin = { Username: DATA_LOGIN.username, Password: DATA_LOGIN.password };
        await this.getLoginToken();
        if (this.props.typeDescr == 'locazione') {
            bodyExport.Tipologia = 'Locazione';
        }
            let comuni = [...this.state.comuneName];
            var index = -1;
            for (var j = 0; j < comuni.length; j++) {
                if (comuni[j] === 'Provincia di Milano') {
                    for(var i = 0; i < this.comuniFuoriMilano.length; i++){
                      comuni.push(this.comuniFuoriMilano[i].id);
                    }
                    index = j;
                }
                comuni[j] = comuni[j].toUpperCase();
            }
            if (index !== -1) {
                comuni.splice(index, 1);
            }
            let polyMap = [...this.state.listPolyMap];
            for (var i = 0; i < polyMap.length; i++) {
                if (polyMap[i].checked && polyMap[i].id !== 'ALL') {
                    comuni.push(polyMap[i].id);
            }
        }
        bodyExport.Comune = comuni;
        bodyExport.Destinazione = this.state.destinazioneName;
        bodyExport.Locali = this.state.localeName;
                // bodyExport.ValoreImmobileDa = this.state.valuesSlider[0];
                // bodyExport.ValoreImmobileA = this.state.valuesSlider[1];
                // bodyExport.SuperficieDa = this.state.valuesSuperfcie[0];
                // bodyExport.SuperficieA = this.state.valuesSuperfcie[1];
                // bodyExport.Comune = comuni;
                // bodyExport.Destinazione = this.state.destinazioneSelected;
                // bodyExport.Locali = this.state.localiSelected;
        bodyExport.ValoreImmobileDa = this.state.valueImmobileFrom ? this.state.valueImmobileFrom : null;
        bodyExport.ValoreImmobileA = this.state.valueImmobileTo ? this.state.valueImmobileTo : null;
        bodyExport.SuperficieDa = this.state.valueSuperficieFrom ? this.state.valueSuperficieFrom : null;
        bodyExport.SuperficieA = this.state.valueSuperficieTo ? this.state.valueSuperficieTo : null;
        bodyExport.TIPOLOGIA = bodyExport.Tipologia;
        bodyExport.NUMERO = 3;
        bodyExport.Token = this.apiToken;
        axios.post('https://apifilcasaagency.ingage.eu/Ricerca', bodyExport)
            .then(res => {
                this.setState({ apartmentList: res.data.Immobili });
                this.setState({ showSpinner: false });
                if (res.data.Immobili.length === 0) {
                    this.setState({ showMessage: true });
                    axios.post('https://apifilcasaagency.ingage.eu/Ultimi', bodyExport)
                        .then(res => {
                              this.setState({ apartmentList: res.data.Immobili });
                        })
                }
            })
            .catch((error) => {
            console.log(error.config);
            });
    }

    elementsInArray = true;
    checkForElementsInArray(){
      if(this.state.apartmentList.length - this.state.limitTo <= 0){
        this.elementsInArray = false; // variabile settata a false visto che NON ci sono elementi nell'array
      }
      // else if aggiunto giusto per chiarezza. Basterebbe un return true; al di fuori dell'if(){}
      else if(this.state.apartmentList.length - this.state.limitTo > 0){
        this.elementsInArray = true; // variabile settata a true visto che ci sono elementi nell'array
      }
    }

    onchangeSelZone(zoneIdSel){
        this.setState({ polySusa:{fillColor:'#E3F5ED', fillOpacity:.5, strokeColor:'#91C3A3', zIndex: 2}});
        for (var i = 0; i < this.listZone.length; i++) {
            if(this.listZone[i].id == zoneIdSel){
                this.listZone[i].checked = !this.listZone[i].checked;
                break;
            }
        }
    }
    showMap = event =>{
        if(event === 'Milano'){
            this.setState({showMapCity:!this.state.showMapCity});
        }
    }
    hideMap = event =>{
        var selzone = false;
        for (var i = 0; i < this.state.listPolyMap.length; i++) {
            if (this.state.listPolyMap[i].checked === true) {
                selzone = true;
            }
        }
        var tmp = this.state.citySelected;
        var index = -1;
        for (var j = 0; j < tmp.length; j++) {
            if (tmp[j] === 'Milano') {
                index = j;
                break;
            }
        }
        if (index === -1 && selzone) {
            tmp.push('Milano');
        }
        if (index !== -1 && !selzone) {
            tmp.splice(index, 1);
        }
        this.setState({ citySelected: tmp });
        window.sessionStorage.setItem("zone", JSON.stringify(this.state.citySelected));
        window.sessionStorage.setItem("polymap", JSON.stringify(this.state.listPolyMap));
        this.setState({ showMapCity: !this.state.showMapCity });
    }
    // handleChange = (event) => {
    //     const { target: { value } } = event;
    //     this.setState({citySelected: typeof value === 'string' ? value.split(',') : value});
    //   };
    // showMap = event =>{
    //     this.setState({showMapCity:event});
    // }

    onchangeDropVal = event => {
        const value = event;
        console.log(value);
        switch(event.idSel){
            case 'comune':
                this.setState({citySelected:[...this.state.citySelected, event.value]});
                break;
            case 'destinazione':
                this.setState({destinazioneSelected:[...this.state.destinazioneSelected, event.value]});
                break;
            case 'locali':
                this.setState({localiSelected:[...this.state.localiSelected, parseInt(event.value)]});
                break;
        }
    };

    addItemList = event => {
        this.setState({limitTo:this.state.limitTo+10})
    }


    handleChange = (event) => {
        const { target: { value } } = event;
        this.setState({setComuneName: [...this.state.setComuneName, (typeof value === 'string' ? value.split(',') : value)]});
        this.setState({comuneName: typeof value === 'string' ? value.split(',') : value});
      };

    handleChangeDestinazione = (event) => {
        const { target: { value } } = event;
        this.setState({setDestinazioneName: [...this.state.setDestinazioneName, (typeof value === 'string' ? value.split(',') : value)]});
        this.setState({destinazioneName: typeof value === 'string' ? value.split(',') : value});
        this.setState({destinazioneSelected : typeof value === 'string' ? value.split(',') : value});
    };

    handleChangeLocale = (event) => {
        const { target: { value } } = event;
        this.setState({setLocaleName: [...this.state.setLocaleName, (typeof value === 'string' ? value.split(',') : value)]});
        this.setState({localeName: typeof value === 'string' ? value.split(',') : value});
    };
    onchangeSlider = (event, newValue) =>{
        this.setState({valuesSlider: newValue});
    }
    onchangeSuperficie = (event, newValue) =>{
        this.setState({valuesSuperfcie: newValue});
    }
    onInputchange = (event) => {
        this.setState({valueImmobileFrom: event.target.value});
    }
    onInputchangeImmobileTo = (event) => {
        this.setState({valueImmobileTo: event.target.value});
    }
    onInputchangeSuperficieFrom = (event) => {
        this.setState({valueSuperficieFrom: event.target.value});
    }
    onInputchangeSuperficieTo = (event) => {
        this.setState({valueSuperficieTo: event.target.value});
    }
    openZone = event => {
        this.setState({zone:true});
    }
    closeZone = event => {
        this.setState({zone:false});
    }

    render() {
        return (
        <>
            <div className='containerHeaderPage'>
                <h1 className='titleHeader'>Immobili in <b>{this.props.typeDescr}</b></h1>
                <Row>
                    <Col xs={12} sm={12} md={2} lg={2}>
                        <h4 className='labelSelect'>Comune</h4>
                        {/* <Select
                            className={this.state.comuneName.length > 0 ? 'selectFilter':'selectFilterEmpty'}
                            multiple
                            displayEmpty
                            value={this.state.comuneName}
                            onChange={this.handleChange}
                            renderValue={(selected) => {
                                if (selected.length === 0) {
                                return <em>Seleziona</em>;
                                }
                                return selected.join(', ');
                            }}
                            MenuProps={MenuProps}
                            inputProps={{ 'aria-label': 'Without label' }}
                            >
                            {comuni.map((name) => (
                                <MenuItem className='itemSelect' key={name.id} value={name.descr}>{name.descr}</MenuItem>
                            ))}
                        </Select> */}
                        <Select
                            className={this.state.comuneName.length > 0 ? 'selectFilter':'selectFilterEmpty'}
                            multiple
                            displayEmpty
                            value={this.state.comuneName}
                            onChange={this.handleChange}
                            renderValue={(selected) => {
                                if (selected.length === 0) {
                                return <em>Seleziona</em>;
                                }
                                return selected.join(', ');
                            }}
                            inputProps={{ 'aria-label': 'Without label' }}
                            >
                            {comuni.map((name) => (
                                <MenuItem className='itemSelect' key={name.id} value={name.descr} onClick={() => this.showMap(name.id)}>
                                    <Checkbox checked={this.state.comuneName.indexOf(name.id) > -1} className="checkboxList"
                                    icon={<CircleRoundedIcon />}
                                    checkedIcon={<CheckCircleIcon style={{color:'#00853F'}}/>}
                                     />{name.descr}
                                    {/* {name.id === 'Milano' &&
                                        <button className="btnDropSelect" onClick={() => this.showMap()}>
                                            <div className="iconMapItem"></div>
                                            <div>ZONE</div>
                                        </button>
                                    } */}
                                </MenuItem>
                            ))}
                        </Select>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2}>
                        <h4 className='labelSelect'>Destinazione</h4>
                        {/* <Select
                            className={this.state.destinazioneName.length > 0 ? 'selectFilter':'selectFilterEmpty'}
                            multiple
                            displayEmpty
                            value={this.state.destinazioneName}
                            onChange={this.handleChangeDestinazione}
                            renderValue={(selected) => {
                                if (selected.length === 0) {
                                return <em>Seleziona</em>;
                                }
                                return selected.join(', ');
                            }}
                            MenuProps={MenuProps}
                            inputProps={{ 'aria-label': 'Without label' }}
                            >
                            {destinazioni.map((destinazione) => (
                                <MenuItem className='itemSelect' key={destinazione.id} value={destinazione.descr}>{destinazione.descr}</MenuItem>
                            ))}
                        </Select> */}
                        <Select
                            className={this.state.destinazioneName.length > 0 ? 'selectFilter':'selectFilterEmpty'}
                            multiple
                            displayEmpty
                            value={this.state.destinazioneName}
                            onChange={this.handleChangeDestinazione}
                            renderValue={(selected) => {
                                if (selected.length === 0) {
                                return <em>Seleziona</em>;
                                }
                                return selected.join(', ');
                            }}
                            inputProps={{ 'aria-label': 'Without label' }}
                            >
                            {destinazioni.map((destinazione) => (
                                <MenuItem className='itemSelect' key={destinazione.id} value={destinazione.descr}>
                                    <Checkbox checked={this.state.destinazioneSelected.indexOf(destinazione.id) > -1} className="checkboxList"
                                        icon={<CircleRoundedIcon />}
                                        checkedIcon={<CheckCircleIcon style={{color:'#00853F'}}/>}
                                     />{destinazione.descr}</MenuItem>
                            ))}
                        </Select>
                    </Col>
                    <Col xs={12} sm={12} md={3} lg={3}>
                        <h4 className='labelSelect'>Valore immobile</h4>
                        {/* <Select
                            className={(this.state.valuesSlider[0] > 200000 || this.state.valuesSlider[1] < 5000000) ? 'selectFilter':'selectFilterEmpty'}
                            displayEmpty
                            renderValue={() => {
                                if(this.state.valuesSlider[0] == 200000 && this.state.valuesSlider[1] == 5000000){
                                    return <em>200.000 - 5.000.000 €</em>;
                                }
                                return <em>{(this.state.valuesSlider[0]).toLocaleString()} - {(this.state.valuesSlider[1]).toLocaleString()} €</em>
                            }}
                            inputProps={{ 'aria-label': 'Without label' }}>
                                <div className="containerSlider">
                                    <div className="rowPrices">
                                        <div className="prices pricesSx">{(this.state.valuesSlider[0]).toLocaleString()} €</div>
                                        <div className="prices pricesDx">{(this.state.valuesSlider[1]).toLocaleString()} €</div>
                                    </div>
                                    <div className="containerSliderComponent">
                                        <Slider
                                            min={200000} max={5000000}
                                            step={200000}
                                            getAriaLabel={() => 'Valore immobile'}
                                            value={this.state.valuesSlider}
                                            onChange={this.onchangeSlider}
                                            sx={{color: '#00853F'}}
                                        />
                                    </div>
                                </div>
                        </Select> */}
                        <Select
                            className={(this.state.valueImmobileFrom != '' || this.state.valueImmobileTo != '') ? 'selectFilter':'selectFilterEmpty'}
                            multiple
                            value={this.state.emptyValue}
                            displayEmpty
                            renderValue={() => {
                                if(this.state.valueImmobileFrom == '' && this.state.valueImmobileTo == ''){
                                    return <em>Da - a</em>;
                                }
                                return <em>{(this.state.valueImmobileFrom) ? parseInt(this.state.valueImmobileFrom).toLocaleString(): '0'}  {(this.state.valueImmobileTo) ? " - " + parseInt(this.state.valueImmobileTo).toLocaleString(): ''} €</em>
                            }}
                            inputProps={{ 'aria-label': 'Without label' }}>
                                <div className="containerSlider">
                                    <div className="rowPrices">
                                        <span className="prices pricesSx">min</span>
                                        {/* <div className="input-box"> */}
                                            {/* <span className="prefix">€</span> */}
                                            <input className='inputSliderFilter' type="number" placeholder='€'
                                            value={this.state.valueImmobileFrom}
                                            onChange={this.onInputchange}
                                            />
                                        {/* </div> */}
                                    </div>
                                    <div className="rowPrices">
                                        <span className="prices pricesSx">max</span>
                                        {/* <div className="input-box"> */}
                                            {/* <span className="prefix">€</span> */}
                                            <input className='inputSliderFilter' type="number" placeholder='€'
                                            value={this.state.valueImmobileTo}
                                            onChange={this.onInputchangeImmobileTo}
                                            />
                                        {/* </div> */}
                                    </div>
                            </div>
                        </Select>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2}>
                        <h4 className='labelSelect'>Superficie</h4>
                            {/* <Select
                                className={(this.state.valuesSuperfcie[0] > 60 || this.state.valuesSuperfcie[1] < 400) ? 'selectFilter':'selectFilterEmpty'}
                                displayEmpty
                                renderValue={() => {
                                    if(this.state.valuesSuperfcie[0] == 60 && this.state.valuesSuperfcie[1] == 400){
                                        return <em>60 - 400 mq</em>;
                                    }
                                    return <em>{this.state.valuesSuperfcie[0]} - {this.state.valuesSuperfcie[1]} mq</em>
                                }}
                                inputProps={{ 'aria-label': 'Without label' }}>
                                    <div className="containerSlider">
                                        <div className="rowPrices">
                                            <div className="prices pricesSx">{this.state.valuesSuperfcie[0]} mq</div>
                                            <div className="prices pricesDx">{this.state.valuesSuperfcie[1]} mq</div>
                                        </div>
                                        <div className="containerSliderComponent">
                                            <Slider
                                                min={60} max={400}
                                                step={20}
                                                getAriaLabel={() => 'Superficie'}
                                                value={this.state.valuesSuperfcie}
                                                onChange={this.onchangeSuperficie}
                                                sx={{color: '#00853F'}}
                                            />
                                        </div>
                                    </div>
                            </Select> */}
                            <Select
                                className={(this.state.valueSuperficieFrom != '' || this.state.valueSuperficieTo != '') ? 'selectFilter':'selectFilterEmpty'}
                                multiple
                                value={this.state.emptyValue}
                                displayEmpty
                                renderValue={() => {
                                    if(this.state.valueSuperficieFrom == '' && this.state.valueSuperficieTo == ''){
                                        return <em>Da - a</em>;
                                    }
                                    return <em>{(this.state.valueSuperficieFrom) ? parseInt(this.state.valueSuperficieFrom).toLocaleString(): '0'}  {(this.state.valueSuperficieTo) ? " - " + parseInt(this.state.valueSuperficieTo).toLocaleString(): ''} mq</em>
                                }}
                                inputProps={{ 'aria-label': 'Without label' }}>
                                    <div className="containerSlider">
                                        <div className="rowPrices">
                                            <span className="prices pricesSx">min</span>
                                            <input className='inputSliderFilter' type="number" placeholder='mq'
                                                value={this.state.valueSuperficieFrom}
                                                onChange={this.onInputchangeSuperficieFrom}
                                            />
                                        </div>
                                        <div className="rowPrices">
                                            <span className="prices pricesSx">max</span>
                                            <input className='inputSliderFilter' type="number" placeholder='mq'
                                                value={this.state.valueSuperficieTo}
                                                onChange={this.onInputchangeSuperficieTo}
                                            />
                                        </div>
                                </div>
                            </Select>
                    </Col>
                    <Col xs={12} sm={12} md={1} lg={1}>
                        <h4 className='labelSelect'>Locali</h4>
                        <Select
                            className={this.state.localeName.length > 0 ? 'selectFilter':'selectFilterEmpty'}
                            multiple
                            displayEmpty
                            value={this.state.localeName}
                            onChange={this.handleChangeLocale}
                            input={<OutlinedInput />}
                            renderValue={(selected) => {
                                if (selected.length === 0) {
                                return <em>N.</em>;
                                }
                                return selected.join(', ');
                            }}
                            MenuProps={MenuProps}
                            inputProps={{ 'aria-label': 'Without label' }}
                            >
                            {locali.map((locale) => (
                                <MenuItem className='itemSelect' key={locale.id} value={locale.id}>
                                    <Checkbox checked={this.state.localeName.indexOf(locale.id) > -1} className="checkboxList"
                                        icon={<CircleRoundedIcon />}
                                        checkedIcon={<CheckCircleIcon style={{color:'#00853F'}}/>}
                                    />
                                {locale.descr}</MenuItem>
                            ))}
                        </Select>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} style={{alignSelf:'end'}}>
                        <div className='iconProced'>
                            <Button variant="primary" className='mainBtn' onClick={() => this.getListAppartment()}>
                                <div className='contMinBtn'>
                                    <div className='textMainBtn'>Cerca</div>
                                    <div className='iconMainButton iconBtnProced'></div>
                                </div>
                            </Button>
                        </div>
                    </Col>
                </Row>
                {this.state.showMapCity &&

                            <div className='dialogMap'>
                                <div className="map-desktop" style={{display:'flex'}}>
                                    <div className='containerMap'>
                                        <Row>
                                            <Col xs={12} sm={12} md={7} lg={7} className='colGoogleMap'>
                                                <LoadScript googleMapsApiKey="AIzaSyAk3K2wgZc3M7n3RWSqWnIv0u5fM1n7HWc">
                                                    <GoogleMap
                                                        id="mapMilano"
                                                        // mapContainerStyle={(window.innerWidth > 993 && window.innerWidth < 1920) ? mapContainerStyle : mapContainerStyle1920}
                                                        mapContainerStyle={this.responsiveStyleMap}
                                                        zoom={11.9}
                                                        center={center}
                                                        options={{
                                                            styles: gMapStyle,
                                                            fullscreenControl: false,
                                                            zoomControl: false,
                                                            streetViewControl: false,
                                                            mapTypeControl: false
                                                            }}
                                                        >
                                                        {this.state.listPolyMap.map((poly,i) => (
                                                            <Polygon
                                                                key={i}
                                                                paths={eval(poly.coordinates)}
                                                                options={poly.checked ? {fillColor:'#E3F5ED',fillOpacity:.5, strokeColor:'#91C3A3', zIndex: 2}:{fillColor:'transparent', fillOpacity:0, strokeColor:'#707070', zIndex: 1}}
                                                                onMouseOver={e => this.onMouseOver(poly.id, false)}
                                                                onMouseOut={e => this.onMouseOver1(poly.id, false)}
                                                                onClick={e => this.onMouseOver(poly.id, true)}
                                                            />
                                                        ))}
                                                    </GoogleMap>
                                                </LoadScript>
                                            </Col>
                                            <Col xs={12} sm={12} md={5} lg={5}>
                                                <div className='containerSelMap'>
                                                    <div className='boxSelMap'>
                                                        <div className='containerHeaderListDistrict'>
                                                            <div className='tileSelMap'>SELEZIONA UNA O PIÙ ZONE­­­</div>
                                                            <div className='separatorMap'></div>
                                                        </div>
                                                        <div className='containerListDistrict'>
                                                            {this.state.listPolyMap.map((poly,i) => (
                                                                <div key={i} className={poly.checked ? 'containerZone containerZoneSel':'containerZone'}>
                                                                    <div className="round">
                                                                        <input type="checkbox" id={"checkbox" + poly.id} checked={poly.checked} onChange={e => this.onMouseOver(poly.id, true)} />
                                                                        <label htmlFor={"checkbox" + poly.id}></label>
                                                                    </div>
                                                                    <div htmlFor={"checkbox" + poly.id} className='titleZone'>{poly.descr}</div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                        <div className='ContmainBtnSelMap'>
                                                        <Button variant="primary" type="submit" className='mainBtn btnConfirmDistrict' onClick={() => this.hideMap()}>
                                                            <div className='contMinBtn contMainBtnDistrict'>
                                                                <div className='textMainBtn'>Conferma e procedi</div>
                                                                <div className='iconMainButton iconBtnProced'></div>
                                                            </div>
                                                        </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <Button className='btnCloseMap'><div className = 'btnCloseMapText' onClick={() => this.hideMap()}>X</div></Button>
                                </div>
                                {/* map mobile*/}

                                <div className="map-mobile">
                                    <div className='containerMap-mobile'>
                                        <Row className='row-map-mobile'>
                                            <Col className='colGoogleMap'>
                                                <LoadScript googleMapsApiKey="AIzaSyAk3K2wgZc3M7n3RWSqWnIv0u5fM1n7HWc">
                                                    <GoogleMap
                                                        id="mapMilano-mobile"
                                                        // mapContainerStyle={(window.innerWidth > 993 && window.innerWidth < 1920) ? mapContainerStyle : mapContainerStyle1920}
                                                        mapContainerStyle={this.responsiveStyleMap}
                                                        zoom={11.9}
                                                        center={center}
                                                        options={{
                                                            styles: gMapStyle,
                                                            fullscreenControl: false,
                                                            zoomControl: false,
                                                            streetViewControl: false,
                                                            mapTypeControl: false
                                                            }}
                                                        >
                                                        {this.state.listPolyMap.map((poly,i) => (
                                                            <Polygon
                                                                key={i}
                                                                paths={eval(poly.coordinates)}
                                                                options={poly.checked ? {fillColor:'#E3F5ED',fillOpacity:.5, strokeColor:'#91C3A3', zIndex: 2}:{fillColor:'transparent', fillOpacity:0, strokeColor:'#707070', zIndex: 1}}
                                                                onMouseOver={e => this.onMouseOver(poly.id, false)}
                                                                onMouseOut={e => this.onMouseOver1(poly.id, false)}
                                                                onClick={e => this.onMouseOver(poly.id, true)}
                                                            />
                                                        ))}
                                                    </GoogleMap>
                                                </LoadScript>
                                            </Col>

                                            <Button className='btnCloseMap-mobile'><div className = 'btnCloseMapText-mobile' onClick={() => this.hideMap()}>CONFERMA E PROSEGUI</div></Button>
                                            <div className="btn-zone" onClick={() => this.openZone()}></div>
                                        </Row>

                                        <Row>
                                            <Col className={this.state.zone ? 'col-zona col-zona-open' : 'col-zona col-zona-closed'} >
                                                <div className='containerSelMap-mobile'>
                                                    <div className='boxSelMap-mobile'>
                                                        <div className='containerHeaderListDistrict-mobile'>
                                                            <div className='tileSelMap-mobile'>SELEZIONA UNA O PIÙ ZONE­­­</div>
                                                            <FontAwesomeIcon className="close-zone" icon={faClose} onClick={() => this.closeZone()}/>
                                                        </div>
                                                        <div className='separatorMap-mobile'></div>
                                                        <div className='containerListDistrict-mobile'>
                                                            {this.state.listPolyMap.map((poly,i) => (
                                                                <div key={i} className={poly.checked ? 'containerZone containerZoneSel':'containerZone'}>
                                                                    <div className="round">
                                                                        <input type="checkbox" id={"checkbox" + poly.id} checked={poly.checked} onChange={e => this.onMouseOver(poly.id, true)} />
                                                                        <label htmlFor={"checkbox" + poly.id}></label>
                                                                    </div>
                                                                    <div htmlFor={"checkbox" + poly.id} className='titleZone'>{poly.descr}</div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                            <div className={this.state.zone ? 'gradient-zone d-block' : 'gradient-zone d-none'}>
                                                <div className = 'btnCloseMapText-mobile close-zone-mobile' onClick={() => this.closeZone()}></div>
                                            </div>

                                        </Row>
                                    </div>
                                    {/* <div className='btnCloseMap'> */}

                                    {/* </div> */}
                                </div>
                            </div>
                        }
            </div>
            <div className='mainContainer'>
                <div className='containerBodyPage'>
                    {/* <div className='headerCardList'>
                        <div className='rowFilterCardList'>
                            <p>VISUALIZZA</p>
                            <div>griglia</div>
                            <div>mappa</div>
                        </div>
                    </div> */}
                    <div className='bodyCardList'>
                   { this.state.showSpinner && <div className="spinner-container">
                    <div className="loading-spinner"></div>
                    <span className="text-blockricerca">Caricamento...</span>
                    </div>}
                    { !this.state.showSpinner && this.state.showMessage && <div className='containerPres-view'>
                                    <h2 className ="title-blockricerca">Stiamo lavorando per adeguare al meglio i risultati della tua ricerca.</h2>
                                <h4 className="text-blockricerca"><b>Scopri la selezione del mese</b> tra gli esclusivi immobili di FilCasa Agency!</h4>

                                </div>}
                        <Row>
                            { this.state.apartmentList.slice(0, this.state.limitTo).map((apartment,i) => (
                                <Col xs={4} sm={4} md={4} lg={4} className="boxCardApartment" key={i}>
                                    <Link className='glCardAppartment boxCardApartment' key={i} to={'/'+ this.props.typeDescr +'-immobili/'+apartment.Id_Immobile}>
                                        <Card key={i} className="styleCardApartment">
                                            <Card.Img variant="top" src={apartment?.Vetrina?.Valore} />
                                            <div className="box-price-card">{apartment.Prezzo.toLocaleString()} €</div>
                                            <Card.Body>
                                                <div className='containerCardText'>
                                                    <h3 className='titleCardApartment'>{apartment.Titolo}</h3>
                                                    <div style={{display: "flex", alignItems: "center", justifyContent: "center", marginTop: 20}}>
                                                        <div className='boxIconCardAppartment'>
                                                            <div className='imgCardMq'></div>
                                                            <p className='textIconCard'><b>{apartment.Superficie}</b> mq</p>
                                                        </div>
                                                        <div className='boxIconCardAppartment'>
                                                            <div className='imgCardLocali'></div>
                                                            <p className='textIconCard'><b>{apartment.Locali}</b> locali</p>
                                                        </div>
                                                        {apartment.Piano && (
                                                            <div className='boxIconCardAppartment'>
                                                            <div className='imgCardPiano'></div>
                                                            <p className='textIconCard'><b>piano {apartment.Piano}</b> </p>
                                                        </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </Card.Body>
                                            <Card.Footer className='cardFooter'>
                                                <div>
                                                    <div className='itemCardFooter' style={{float: "left"}}>
                                                        <b><small>MOSTRA DI PIU</small></b>
                                                    </div>
                                                    <div className='itemCardFooter' style={{float: "right"}}>
                                                        <b><IoIosArrowForward></IoIosArrowForward></b>
                                                    </div>
                                                </div>
                                            </Card.Footer>
                                        </Card>
                                    </Link>
                                </Col>
                            ))}
                            {this.checkForElementsInArray()}
                        </Row>
                        {/*this.checkForElementsInArray()*/}
                        { this.elementsInArray &&
                          <div className='footerCardList'>
                              {/*bottone 'ALTRI RISULTATI'*/}
                              <div className='iconBtnAddList' onClick={() => this.addItemList()}></div>
                          </div>
                        }

                    </div>
                </div>
                <div className='containerInfoApartment'>
                    <Row style={{justifyContent:'center'}}>
                        <Col xs={12} sm={12} md={6} lg={7} style={{display:'flex'}}>
                            <Col xs={12} sm={12} md={6} lg={6}>
                                <div className='iconInfoAp'></div>
                                    <div className='titleInfoAp'><b className="titleInfoAp-strong">Non trovi ciò che stai cercando?</b></div>
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={6}>
                                <p className='textInfoAp'>Valutiamo insieme la soluzione più adatta alle tue esigenze</p>
                                <Link to={'/Contatti'}>
                                    <Button variant="primary" className='mainBtn'>
                                        <div className='contMinBtn'>
                                            <div className='textMainBtn'>Richiedi informazioni</div>
                                        </div>
                                    </Button>
                                </Link>
                            </Col>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
        )
    }
}
export default ViewApartment;
