import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Menu from "./components/Menu/menu";
import Footer from "./components/Footer/footer";

ReactDOM.render(
  <React.StrictMode>
    {/* <Menu /> */}
    <App />
    {/* <Footer /> */}
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
