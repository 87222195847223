import * as React from "react";
import Footer from "../components/Footer/footer";
import Privacy from "../components/Privacy/privacy";
import Menu from "../components/Menu/menu";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';

const pageStyles = {color: "#232129"}

const PrivacyPage = () => {
  return (
    <main style={pageStyles}>
      <Menu/>
      <Privacy />
      <Footer />
    </main>
  )
}

export default PrivacyPage
