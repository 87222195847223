import * as React from "react";
import axios from 'axios';
import "./detail.css";
import { Container, Row, Col, Button,Tabs, Tab, Form, Card} from 'react-bootstrap';
import { BsPrinterFill } from 'react-icons/bs'
import "react-multi-carousel/lib/styles.css";
import { DATA_LOGIN } from '../../constants'
import { BsDoorOpen, BsSearch, BsArrowUpRight } from "react-icons/bs";
import { IoIosGitCompare,IoIosArrowBack, IoMdShare, IoMdPrint } from "react-icons/io";
import { AiOutlineFileDone, AiOutlineClose } from "react-icons/ai";
import Modal from "react-bootstrap/Modal";
import ImageGallery from 'react-image-gallery';
import GoogleMapReact from 'google-map-react';
import gMapStyle from "../../style/gMapStyle";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import iconaMappa from "../../images/icons/icona-pin-mappa.svg";
import ReactPlayer from 'react-player'
import iconStampa from '../../images/icons/ic-stampa.svg'

const bodyExport = {
    "Token": "",
    "Id": "",
}

const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 2
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
};

class Detail extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            Immobile: {
                Dettagli:{},
                Vetrina:{},
                Foto:[{Valore:""},{Valore:""},{Valore:""}],
                Planimetria:[],
                Video:[],
                Descrizione_Breve: ""
            },
            showModal: false,
            showModalVideo: false,
            videoToShow: '',
            imgGallery: [{original:"",thumbnail:"" }],
            center: {
                lat: 0,
                lng: 0
            }};
            bodyExport.Id = this.props.idApartmentSelcted;
      }

      componentDidMount() {
        this.search();
      }

      search(){
        const dataLogin = { Username: DATA_LOGIN.username, Password:DATA_LOGIN.password };
        axios.post('https://apifilcasaagency.ingage.eu/Login', dataLogin)
        .then(res => {
            bodyExport.Token = res.data.Token;
            axios.post('https://apifilcasaagency.ingage.eu/Immobile', bodyExport)
            .then(res => {
                const piano = res.data.Dettagli.Piano.replace(">", "");
                res.data.Dettagli.Piano = piano;
                const pianiTot = res.data.Dettagli.PianiEdificio.replace(">", "");
                res.data.Dettagli.PianiEdificio = pianiTot;

                this.setState({Immobile: res.data});
                let array = [];
                res.data.Foto.map((file,i) => (
                    array.push({original: file.Valore, thumbnail: file.Valore })
                ));
                this.setState({imgGallery: array});

                this.setState({
                    center: {
                        lat: parseFloat(this.state.Immobile.Dettagli.Latitudine),
                        lng: parseFloat(this.state.Immobile.Dettagli.Longitudine)
                    },
                });
            })
            .catch((error) => {
                console.log(error.config);
            });
        })
        .catch((error) => {
            console.log(error.config);
        });

    }

    setModalShow(value){
        this.setState({showModal: value});
    }

    setModalVideo(value, valVideo, nome){
        this.setState({showModalVideo: value});
        if(valVideo.includes("youtube")){
            this.setState({videoToShow: nome});
        } else {
            this.setState({videoToShow: valVideo});
        }

    }


    render() {
        const apiIsLoaded = (map, maps) => {
            if (map) {
                map.setOptions({ center: { lat: this.state.Immobile.Dettagli.Latitudine, lng:  this.state.Immobile.Dettagli.Longitudine }});
            }
        };
        const AnyReactComponent = ({index}) => {
            return (
                <img src={iconaMappa} className="iconPinMap"/>
            ) }
        return (
            <>
                <Container>
                    <Modal
                        show={this.state.showModal}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        fullscreen={true}>
                        <Modal.Header style={{background:"#3c3c3c", borderBottom: "none"}}>
                            <Button onClick={() => this.setModalShow(false)} className="btn-close-modal"><AiOutlineClose></AiOutlineClose></Button>
                            <Modal.Title id="contained-modal-title-vcenter">
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{background:"#3c3c3c"}}>
                            <ImageGallery
                                items={this.state.imgGallery}
                                showPlayButton={false}
                                showFullscreenButton={false}
                                showIndex={true} />
                        </Modal.Body>
                    </Modal>

                    <Modal
                        show={this.state.showModalVideo}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        fullscreen={true}>
                        <Modal.Header style={{background:"#3c3c3c", borderBottom: "none"}}>
                            <Button onClick={() => this.setModalVideo(false, '')} className="btn-close-modal"><AiOutlineClose></AiOutlineClose></Button>
                            <Modal.Title id="contained-modal-title-vcenter">
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{background:"#3c3c3c"}}>
                        {
                            this.state.videoToShow.includes("youtube")
                            ?
                            <ReactPlayer url={this.state.videoToShow} width="100%" height="100%" />
                            :
                            <video width="100%" height="100%" controls>
                            <source src={this.state.videoToShow} type="video/mp4"/>
                            Your browser does not support the video tag.
                            </video>
                        }

                        </Modal.Body>
                    </Modal>

                    <Row style={{paddingLeft: 50, paddingRight:50}}>
                    <Link to={'/'+this.props.typeDescr.toLowerCase()+'-immobili'} style={{width:'auto'}}>
                        <div className="buttonGoBack no-print">
                            <IoIosArrowBack></IoIosArrowBack>
                            <div>TORNA AI RISULTATI</div>
                        </div>
                    </Link>
                    </Row>
                    <Row style={{marginTop: 40, paddingLeft: 50, paddingRight:50}}>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <div className='breadcrumbDetail no-print'>
                            {this.props.typeDescr} / {this.state.Immobile.Dettagli.Comune} / {this.state.Immobile.Dettagli.Destinazione}
                            </div>
                        </Col>
                        <Col xs={12} sm={6} md={6} lg={6}>
                            <div className="addressApartment">
                                <b>{this.state.Immobile.Dettagli.Comune}</b>, {this.state.Immobile.Dettagli.Indirizzo}
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} className="flexCenter">
                            <Col xs={12} sm={6} md={6} lg={6}>
                                <div className="addressTitle">{this.state.Immobile.Dettagli.Zona}</div>
                            </Col>
                            <Col xs={12} sm={6} md={6} lg={6}>
                                <div className='containerPrice' style={{float:'right'}}>
                                    <span className="priceApt">{Number(this.state.Immobile.Prezzo).toLocaleString()} €</span>
                                </div>
                            </Col>
                        </Col>
                    </Row>
                    <Row style={{marginTop: 5, paddingLeft: 50, paddingRight:50}}>
                        <Col className='boxIcons' xs={12} sm={12} md={12} lg={12} style={{display:'flex', marginTop:'20px'}}>
                            {/* <div className="iconShareDet"></div>
                            <div className="iconPrintDet"></div> */}
                            {/* <IoMdShare style={{marginRight: 10, color:"#00853f", fontSize: 20}}> */}
                            {/* </IoMdShare> <IoMdPrint style={{color:"#00853f", fontSize: 20}}></IoMdPrint> */}
                        </Col>
                    </Row>

                    <Row>
                        <div className='icon-print-page no-print' onClick={() => window.print()}>
                          <img src={iconStampa} alt="icona stampa"></img>
                        </div>

                    </Row>

                    <Row style={{marginTop: 20, paddingLeft: 50, paddingRight:50}}>
                        <Col xs={12} sm={7} md={7} lg={7} style={{textAlign: "center"}}>
                            <img className='photoVet' src={this.state.Immobile?.Vetrina?.Valore} style={{maxWidth: "100%"}} alt="valore"/>
                        </Col>
                        <Col xs={12} sm={5} md={5} lg={5}>
                            <Row>
                            {/* { this.state.Immobile.Planimetria &&
                                this.state.Immobile.Planimetria.map((file,i) => (
                                <Col xs={12} sm={6} md={6} lg={6} style={{textAlign: "center"}} key={i}>
                                    <button onClick={()=>window.open(file.Valore)} className="btnDetailApp">
                                        <div style={{color: "#00853f", marginRight: 10}}>{file.Nome}</div> <BsArrowUpRight style={{color:"#00853f"}}></BsArrowUpRight>
                                    </button>
                                </Col>
                                ))
                            }    */}

                            { this.state.Immobile.Foto &&
                                this.state.Immobile.Foto.slice(0,3).map((foto,i) => (
                                    <Col xs={12} sm={6} md={6} lg={6} key={i} style={{paddingBottom:10, textAlign: "center"}}>
                                        <img className='photoValue no-print' src={foto.Valore} alt="foto valore"/>
                                    </Col>
                                ))
                            }
                            { this.state.Immobile.Foto && this.state.Immobile.Foto.length > 3 &&
                                <Col xs={12} sm={6} md={6} lg={6} style={{textAlign: "center"}}>
                                    <div className="boxOtherImg no-print"
                                    onClick={() => this.setModalShow(true)}>
                                        + {this.state.Immobile.Foto.length-3} foto
                                    </div>
                                </Col>
                                }
                                {/* <Col xs={12} sm={6} md={6} lg={6} style={{paddingBottom:10, textAlign: "center"}}>
                                    <img src={this.state.Immobile.Foto[0].Valore} style={{maxWidth: 200, height: 200}}/>
                                </Col>
                                <Col xs={12} sm={6} md={6} lg={6} style={{paddingBottom:10, textAlign: "center"}}>
                                    <img src={this.state.Immobile.Foto[1].Valore} style={{maxWidth: 200, height: 200}}/>
                                </Col> */}
                            </Row>
                            <Row>
                                {/* <Col xs={12} sm={6} md={6} lg={6} style={{paddingTop:10, textAlign: "center"}}>
                                    <img src={this.state.Immobile.Foto[2].Valore} style={{maxWidth: 200, height: 200}}/>
                                </Col> */}
                                {/* <Col xs={12} sm={6} md={6} lg={6} style={{paddingTop:10, textAlign: "center"}}>
                                    <div style={{maxWidth: 200, height: 200, backgroundColor: "#00853f", display: "flex", alignItems:"center",justifyContent: "center", color: "#ffffff", cursor: "pointer"}}
                                         onClick={() => this.setModalShow(true)}>
                                        + {this.state.Immobile.Foto.length-3} foto
                                    </div>
                                </Col> */}
                            </Row>
                        </Col>
                    </Row>

                    <Row className="no-print" style={{marginTop: 20, paddingLeft: 50, paddingRight:50}}>
                        <Col className='colDescDet' xs={12} sm={6} md={6} lg={6} style={{textAlign: "center", borderRight: "1px solid #a1a1a187" }}>
                            <Row>
                                <Col xs={12} sm={6} md={6} lg={6} style={{textAlign: "left"}}>
                                    <div className='descDetails' style={{fontSize:10, color: "#565456", fontWeight: "bold"}}>PLANIMETRIE</div>
                                </Col>
                            </Row>
                            <Row style={{marginTop:10}}>
                            { this.state.Immobile.Planimetria &&
                                this.state.Immobile.Planimetria.map((file,i) => (
                                <Col className="col-xl-3" style={{textAlign: "center"}} key={i}>
                                    <button onClick={()=>window.open(file.Valore)} className="btnDetailApp">
                                        <div style={{color: "#00853f", marginRight: 10}}>Planimetria {i+1}</div> <BsArrowUpRight style={{color:"#00853f"}}></BsArrowUpRight>
                                    </button>
                                </Col>
                                ))
                            }
                            </Row>
                        </Col>
                        <Col xs={12} sm={6} md={6} lg={6}>
                            <Row className='rowDescDet' style={{height:"100%"}}>
                            {this.state.Immobile.Altro &&
                                <Col className='colDescDet 2' xs={12} sm={6} md={6} lg={6} style={{textAlign: "left", borderRight: "1px solid #a1a1a187"}}>
                                    <div className='descDetails' style={{fontSize:10, color: "#565456", fontWeight: "bold"}}>DOCUMENTI</div>
                                    <Row>
                                        {this.state.Immobile.Altro &&
                                            this.state.Immobile.Altro.map((file,i) => (
                                                <Col style={{textAlign: "center"}} key={i}>
                                                    <button onClick={()=>window.open(file.Valore)} className="btnDetailApp">
                                                        <div style={{color: "#00853f", marginRight: 10}}>Brochure/Capitolato {i+1}</div> <BsArrowUpRight style={{color:"#00853f"}}></BsArrowUpRight>
                                                    </button>
                                                </Col>
                                            ))
                                        }
                                    </Row>
                                </Col>
                                }
                                {  this.state.Immobile.Video &&
                                <Col className='colDescDet' xs={12} sm={6} md={6} lg={6} style={{textAlign: "left"}}>
                                    <div className='descDetails' style={{fontSize:10, color: "#565456", fontWeight: "bold"}}>VIDEO</div>
                                    <Row>
                                    {  this.state.Immobile.Video &&
                                        this.state.Immobile.Video.map((video,i) => (
                                        <Col style={{textAlign: "center"}} key={i}>
                                            <button onClick={() => this.setModalVideo(true, video.Valore, video.Nome)} className="btnDetailApp">
                                                <div style={{color: "#00853f", marginRight: 10}}>Video {i+1}</div> <BsArrowUpRight style={{color:"#00853f"}}></BsArrowUpRight>
                                            </button>
                                        </Col>
                                        ))
                                    }
                                    </Row>
                                </Col>}
                            </Row>
                        </Col>
                    </Row>

                    <Row style={{marginTop: 60, marginBottom: 40, paddingLeft: 50, paddingRight:50}}>
                        <Col xs={12} sm={7} md={7} lg={7} className='print-impose-full-width'>
                            <div style={{display: "flex", justifyContent: "center"}}>
                                <div className="mainLabelDetail">
                                    Descrizione
                                </div>
                                <div style={{width: "100%", borderBottom: "1px solid #a1a1a187"}}>
                                </div>
                            </div>
                            <div className="descriptionDetail">
                                {this.state.Immobile.Descrizione_Breve}
                            </div>

                            <div style={{display: "flex", justifyContent: "center", marginTop: 50}}>
                                <div className="mainLabelDetail">
                                    Caratteristiche
                                </div>
                                <div className='no-print' style={{width: "100%", borderBottom: "1px solid #a1a1a187"}}>
                                </div>
                            </div>
                            <div style={{display: "flex", alignItems: "center", marginTop: 20}}>
                                <div className='boxIconCardAppartment boxIconDet'>
                                    <div className='imgCardMq'></div>
                                    <p className='textIconCard'><b>{this.state.Immobile.Superficie}</b> mq</p>
                                </div>
                                <div className='boxIconCardAppartment boxIconDet'>
                                    <div className='imgCardLocali'></div>
                                    <p className='textIconCard'><b>{this.state.Immobile.Locali}</b> locali</p>
                                </div>
                                <div className='boxIconCardAppartment boxIconDet'>
                                    <div className='imgCardPiano'></div>
                                    <p className='textIconCard'><b>Piano {this.state.Immobile.Dettagli.Piano + " di " + this.state.Immobile.Dettagli.PianiEdificio} </b></p>
                                </div>
                            </div>
                            <Row style={{marginTop: 20}}>
                                <Col xs={12} sm={5} md={5} lg={5} >
                                    <div className="container-list-caratteristiche">
                                        <div className="label-caratteristiche-seconda-colonna">Tipologia</div> <div className="text-caratteristiche">{this.state.Immobile.Dettagli.TipoVendita !== "" ? this.state.Immobile.Dettagli.TipoVendita : "-" }</div>
                                    </div>
                                    <div className="container-list-caratteristiche">
                                        <div className="label-caratteristiche-seconda-colonna">Bagni</div> <div className="text-caratteristiche">{this.state.Immobile.Dettagli.Bagni !== "" ? this.state.Immobile.Dettagli.Bagni : "-" }</div>
                                    </div>
                                    <div className="container-list-caratteristiche">
                                        <div className="label-caratteristiche-seconda-colonna">Cucina</div> <div className="text-caratteristiche">{this.state.Immobile.Dettagli.Cucina !== "" ? this.state.Immobile.Dettagli.Cucina : "-" }</div>
                                    </div>
                                    <div className="container-list-caratteristiche">
                                        <div className="label-caratteristiche-seconda-colonna">Balconi</div> <div className="text-caratteristiche">{this.state.Immobile.Dettagli.Balconi !== "" ? this.state.Immobile.Dettagli.Balconi : "-" }</div>
                                    </div>
                                    <div className="container-list-caratteristiche">
                                        <div className="label-caratteristiche-seconda-colonna">Terrazzo</div> <div className="text-caratteristiche">{this.state.Immobile.Dettagli.Terrazzo !== "" ? this.state.Immobile.Dettagli.Terrazzo : "-"}</div>
                                    </div>
                                    <div className="container-list-caratteristiche">
                                        <div className="label-caratteristiche-seconda-colonna">Giardino</div> <div className="text-caratteristiche">{this.state.Immobile.Dettagli.Giardino && this.state.Immobile.Dettagli.Giardino === 'Privato' ? 'Privato - ' + this.state.Immobile.Dettagli.GiardinoMQ + 'mq': this.state.Immobile.Dettagli.Giardino && this.state.Immobile.Dettagli.Giardino !== 'Privato' ? this.state.Immobile.Dettagli.Giardino : '-'}</div>
                                    </div>
                                    <div className="container-list-caratteristiche">
                                        <div className="label-caratteristiche-seconda-colonna">Cantina</div> <div className="text-caratteristiche">{this.state.Immobile.Dettagli.Cantina !== "" ? this.state.Immobile.Dettagli.Cantina : '-' }</div>
                                    </div>
                                    <div className="container-list-caratteristiche">
                                        <div className="label-caratteristiche-seconda-colonna">Posto auto</div> <div className="text-caratteristiche">{
                                            this.state.Immobile.Dettagli.PostoAutoCoperto === 'Si' && this.state.Immobile.Dettagli.PostoAutoScoperto === 'No' ? 'Coperto' :
                                            this.state.Immobile.Dettagli.PostoAutoCoperto === 'No' && this.state.Immobile.Dettagli.PostoAutoScoperto === 'Si' ? 'Scoperto' :
                                            this.state.Immobile.Dettagli.PostoAutoCoperto === 'Si' && this.state.Immobile.Dettagli.PostoAutoScoperto === 'Si' ? 'Coperto / Scoperto' :
                                            this.state.Immobile.Dettagli.PostoAutoCoperto === 'No' && this.state.Immobile.Dettagli.PostoAutoScoperto === 'No' ? 'No' : 'No'
                                        }</div>
                                    </div>
                                    <div className="container-list-caratteristiche">
                                        <div className="label-caratteristiche-seconda-colonna">Box</div> <div className="text-caratteristiche">{this.state.Immobile.Dettagli.Box !== "" ? this.state.Immobile.Dettagli.Box : "-" }</div>
                                    </div>
                                </Col>
                                <Col xs={12} sm={7} md={7} lg={7} >
                                    <div className="container-list-caratteristiche">
                                        <div className="label-caratteristiche-seconda-colonna">Vicinanza metro (max 500m)</div> <div className="text-caratteristiche">{this.state.Immobile.Dettagli.VicinanzaMetropolitana === "true" ? 'Si' : 'No'}</div>
                                    </div>
                                    <div className="container-list-caratteristiche">
                                        <div className="label-caratteristiche-seconda-colonna">Stile edificio</div> <div className="text-caratteristiche">{this.state.Immobile.Dettagli.StileEdificio !== "" ? this.state.Immobile.Dettagli.StileEdificio : "-"}</div>
                                    </div>
                                    <div className="container-list-caratteristiche">
                                        <div className="label-caratteristiche-seconda-colonna">Condizioni appartamento</div> <div className="text-caratteristiche">{this.state.Immobile.Dettagli.CondizioniAppartamento !== "" ? this.state.Immobile.Dettagli.CondizioniAppartamento : "-"}</div>
                                    </div>
                                    <div className="container-list-caratteristiche">
                                        <div className="label-caratteristiche-seconda-colonna">Spese condominiali / mese</div> <div className="text-caratteristiche">{this.state.Immobile.Dettagli.SpeseMese ? (this.state.Immobile.Dettagli.SpeseMese + '€') : ''}</div>
                                    </div>
                                    <div className="container-list-caratteristiche">
                                        <div className="label-caratteristiche-seconda-colonna">Riscaldamento</div> <div className="text-caratteristiche">{this.state.Immobile.Dettagli.Riscaldamento !== "" ? this.state.Immobile.Dettagli.Riscaldamento : "-"}</div>
                                    </div>
                                    <div className="container-list-caratteristiche">
                                        <div className="label-caratteristiche-seconda-colonna">Condizionamento</div> <div className="text-caratteristiche">{this.state.Immobile.Dettagli.TipoCondizionamento !== "" ? this.state.Immobile.Dettagli.TipoCondizionamento : "-"}</div>
                                    </div>
                                    <div className="container-list-caratteristiche">
                                        <div className="label-caratteristiche-seconda-colonna">Portineria</div> <div className="text-caratteristiche">{this.state.Immobile.Dettagli.Portineria !== "" ? this.state.Immobile.Dettagli.Portineria : "-"}</div>
                                    </div>
                                    <div className="container-list-caratteristiche">
                                        <div className="label-caratteristiche-seconda-colonna">Ascensore</div> <div className="text-caratteristiche">{this.state.Immobile.Dettagli.Ascensore !== "" ? this.state.Immobile.Dettagli.Ascensore : "-"}</div>
                                    </div>
                                    <div className="container-list-caratteristiche">
                                        <div className="label-caratteristiche-seconda-colonna">Arredi</div> <div className="text-caratteristiche">{this.state.Immobile.Dettagli.Arredi !== "" ? this.state.Immobile.Dettagli.Arredi : "-"}</div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} sm={5} md={5} lg={5}>
                            <div className="no-print" style={{width: "100%", height: "auto", backgroundColor: "#00853f"}}>
                                <div style={{width: "100%", display: "flex", alignItems:"center", color: "#ffffff",fontSize: 20, padding: 20, borderBottom: "1px solid #ffffff2b"}}>
                                    Key points
                                </div>
                                <div style={{width: "100%", color:"#ffffff", fontSize: 14, marginTop: 30, marginLeft: 30}}>
                                    {this.state.Immobile.Titolo}
                                    <div style={{color:"#d9ece1", fontSize: 12}}>
                                        {this.state.Immobile.Dettagli.NomeImmobile}
                                    </div>
                                </div>
                                <div style={{width: "100%", color:"#ffffff", fontSize: 14, marginTop: 20, marginLeft: 30}}>
                                    {this.state.Immobile.Superficie} mq  |  {this.state.Immobile.Locali} locali  |  piano {this.state.Immobile.Dettagli.Piano}
                                    <div style={{color:"#d9ece1", fontSize: 12}}>
                                    {this.state.Immobile.Dettagli.CondizioniAppartamento}
                                    </div>
                                </div>
                                <div style={{color:"#ffffff", fontSize: 14, marginTop: 20, marginLeft: 30, marginRight: 30, marginBottom: 20, paddingBottom: 20, borderBottom: "1px solid #b6e2c9"}}>

                                </div>
                                <div style={{color:"#ffffff", fontSize: 14, marginTop: 20, marginLeft: 30, marginRight: 30, marginBottom: 20,paddingBottom: 20}}>
                                    {Number(this.state.Immobile.Prezzo).toLocaleString()} €
                                </div>
                            </div>
                            <div className="no-print" style={{width: "100%", height: "auto", backgroundColor: "#dfdfdf", padding:40, textAlign: "center", fontSize:30}}>
                                <div>Sei <b>interessato</b>?</div>
                                <Link to={'/Contatti'}>
                                    <Button variant="primary" className='mainBtn'>
                                            <div className='contMinBtn'>
                                                <div className='textMainBtn'>Richiedi informazioni</div>
                                            </div>
                                    </Button>
                                </Link>
                            </div>
                        </Col>
                    </Row>

                    <Row className="rowEfficienza">
                        <div style={{display:'flex'}}>
                            <h4 className="styleEfficienza">Efficienza energetica</h4>
                            <div className="lineEfficienza no-print"></div>
                        </div>
                        <Col xs={12} sm={5} md={5} lg={5} className='print-impose-full-width' style={{marginTop:50}}>
                            <div className="container-list-caratteristiche">
                                <div className="labelMainEfficienza">Classe Energetica</div> <div className="labelTextEfficienza">{this.state.Immobile.Dettagli.ClasseEnergetica}</div>
                            </div>
                            <div className="container-list-caratteristiche">
                                <div className="labelMainEfficienza">Ep Globale non Rinnovabile</div> <div className="labelTextEfficienza">{this.state.Immobile.Dettagli.EPC} - {this.state.Immobile.Dettagli.UMepc}</div>
                            </div>
                            <div className="container-list-caratteristiche">
                                <div className="labelMainEfficienza">Ep Globale Rinnovabile</div> <div className="labelTextEfficienza">{this.state.Immobile.Dettagli.EPRinnovabile} - {this.state.Immobile.Dettagli.UMepRinnovabile}</div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <div className="mainContainerMap row">
                            <div className="containerHeaderMapDet">
                                <div className="containerTitleMap">
                                    <div className="boxTitleMapDet no-print">
                                        Mappa
                                    </div>
                                    <div className="boxMapDescDet no-print">
                                        <h5 className="subdescMapDetMain">{this.state.Immobile.Dettagli.Comune} - {this.state.Immobile.Dettagli.Indirizzo}</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="containerMapDet">
                                {/* <div style={{ height: 300, width: '100%'}}> */}
                                    <GoogleMapReact
                                        bootstrapURLKeys={{ key: "AIzaSyAk3K2wgZc3M7n3RWSqWnIv0u5fM1n7HWc" }}
                                        options={{
                                        styles: gMapStyle,
                                        fullscreenControl: false,
                                        zoomControl: false
                                        }}
                                        center={this.state.center}
                                        defaultCenter={{lng: 0, lat: 0}}
                                        defaultZoom={15}
                                        yesIWantToUseGoogleMapApiInternals
                                        onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps)}>
                                        <AnyReactComponent
                                            lat={this.state.Immobile.Dettagli.Latitudine}
                                            lng={this.state.Immobile.Dettagli.Longitudine}>
                                        </AnyReactComponent>
                                    </GoogleMapReact>
                                {/* </div> */}
                            </div>
                        </div>
                    </Row>

                    {/* <Row>
                        <div className="backgroundMapDet">
                            <Row className="containerHeaderMapDet">
                                <div className="containerTitleMap">
                                    <div className="boxTitleMapDet">
                                        Mappa
                                    </div>
                                    <div className="boxMapDescDet">
                                        <h5 className="subdescMapDetMain">{this.state.Immobile.Dettagli.Comune} - {this.state.Immobile.Dettagli.Indirizzo}</h5>
                                        <h5 className="subdescMapDet">{this.state.Immobile.Titolo}</h5>
                                    </div>
                                </div>
                                <div className="containerMapDet">
                                    <div style={{ height: 300, width: '100%'}}>
                                        <GoogleMapReact
                                            bootstrapURLKeys={{ key: "AIzaSyAk3K2wgZc3M7n3RWSqWnIv0u5fM1n7HWc" }}
                                            options={{
                                            styles: gMapStyle,
                                            fullscreenControl: false,
                                            zoomControl: false
                                            }}
                                            center={this.state.center}
                                            defaultCenter={{lng: 0, lat: 0}}
                                            defaultZoom={12}
                                            yesIWantToUseGoogleMapApiInternals
                                            onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps)}>
                                            <AnyReactComponent
                                                lat={this.state.Immobile.Dettagli.Latitudine}
                                                lng={this.state.Immobile.Dettagli.Longitudine}>
                                            </AnyReactComponent>
                                        </GoogleMapReact>
                                    </div>
                                </div>
                            </Row>
                        </div>
                    </Row> */}

                    {/* <Row className="headerMapdDetail">
                        <Col xs={12} sm={9} md={9} lg={9}>
                            <div style={{display: "flex",justifyContent: "flex-start", height:"100%"}}>
                                <div className="boxTitleMapDet">
                                    Mappa
                                </div>
                                <div className="boxMapDescDet">
                                    <h5 className="subdescMapDetMain">{this.state.Immobile.Dettagli.Comune} - {this.state.Immobile.Dettagli.Indirizzo}</h5>
                                    <h5 className="subdescMapDet">{this.state.Immobile.Titolo}</h5>
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} sm={3} md={3} lg={3}>
                            <div style={{backgroundColor: "#f3f3f3", height:50, marginRight:-62}}></div>
                        </Col>
                    </Row>
                    <Row style={{backgroundColor: "#00853f",  paddingLeft: 50,paddingRight: 0, paddingBottom: 0}}>
                        <Col xs={12} sm={12} md={12} lg={12} style={{paddingRight: 0}}>
                            <div style={{ height: 300, width: '100%'}}>
                                    <GoogleMapReact
                                        bootstrapURLKeys={{ key: "AIzaSyAk3K2wgZc3M7n3RWSqWnIv0u5fM1n7HWc" }}
                                        options={{
                                        styles: gMapStyle,
                                        fullscreenControl: false,
                                        zoomControl: false
                                        }}
                                        center={this.state.center}
                                        defaultCenter={{lng: 0, lat: 0}}
                                        defaultZoom={12}
                                        yesIWantToUseGoogleMapApiInternals
                                        onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps)}>

                                        <AnyReactComponent
                                            lat={this.state.Immobile.Dettagli.Latitudine}
                                            lng={this.state.Immobile.Dettagli.Longitudine}>
                                        </AnyReactComponent>

                                    </GoogleMapReact>
                            </div>
                        </Col>

                    </Row> */}
                </Container>
            </>
        )
    }
}

export default Detail;
