import React, { Component, PropTypes } from 'react';
import "./privacy.css";
import { Container, Row, Col, Button,Tabs, Tab, Form, Card} from 'react-bootstrap';
import { AiOutlinePhone } from 'react-icons/ai';
import { AiOutlineMail } from 'react-icons/ai';
import { AiOutlineEnvironment } from 'react-icons/ai';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Toast from '../Toast/Toast.js';
//  toast.configure()
//  function GeeksforGeeks(){
//      const notify = ()=>{
//          // Set to 3sec
//          //toast.success('successful', {autoClose:3000})
//          // User have to close it
        
          
//      }
    
//  }
const checktoast = false;

class Privacy extends Component {
    componentDidMount() {
        document.title = "Privacy"; 
     }

    render() {
        return (
            <>
                <Container fluid className='containerContact'>
                    <div className = "section section--vertical">   
                    <h1 className = "h1--tecnico">Privacy Policy</h1>
                    <p><h2>Informazioni di contatto</h2></p>
                    <p><h4>IN SINTESI</h4></p>
                    <p>Benvenuto sul sito web <b>www.filcasaagency.it</b>, di proprietà di F.I.L. Casa Agency S.r.l.<br></br>
                    Responsabile del trattamento:  F.I.L. Casa Agency S.r.l.<br></br>
                    Entrambi domiciliati per la carica presso la sede Via Pindemonte 2 – 20129 Milano</p>
                    
                    <p><h4>APPROFONDIMENTI</h4></p>
                    <p><b>Sede legale:</b> Via Pindemonte 2 – 20129 Milano (MI)<br></br>
                    <b>Partita Iva e Codice Fiscale:</b> 12906720151<br></br>
                    <b>Capitale sociale:</b> € 20.000,00 I.V.REA: MI - 1595668<br></br>
                    <b>Indirizzo email del Titolare:</b> info@filcasaagency.it <br></br>
                    <br></br> </p>
                    <p><h2>Tipologie di raccolta dati</h2></p>
                    <br></br>
                    <p>Fra i Dati Personali raccolti da questo Sito Web, in modo autonomo o tramite terze parti, ci sono: Cookie e Dati di utilizzo.</p>
                    
                    <p>Dettagli completi su ciascuna tipologia di dati raccolti sono forniti nelle sezioni dedicate di questa Privacy Policy o mediante specifici testi informativi visualizzati prima della raccolta dei dati stessi. I Dati Personali possono essere liberamente forniti dall'Utente o, nel caso di Dati di Utilizzo, raccolti automaticamente durante l'uso di questo Sito Web. Se non diversamente specificato, tutti i Dati richiesti da questo Sito Web sono obbligatori. Se l’Utente rifiuta di comunicarli, potrebbe essere impossibile per questo Sito Web fornire il Servizio. Nei casi in cui questo Sito Web indichi alcuni Dati come facoltativi, gli Utenti sono liberi di astenersi dal comunicare tali Dati, senza che ciò abbia alcuna conseguenza sulla disponibilità del Servizio o sulla sua operatività.
Gli Utenti che dovessero avere dubbi su quali Dati siano obbligatori, sono incoraggiati a contattare il Titolare. L’eventuale utilizzo di Cookie - o di altri strumenti di tracciamento - da parte di questo Sito Web o dei titolari dei servizi terzi utilizzati da questo Sito Web, ove non diversamente precisato, ha la finalità di fornire il Servizio richiesto dall'Utente, oltre alle ulteriori finalità descritte nel presente documento e nella Cookie Policy, se disponibile.</p>
                    <p> L'Utente si assume la responsabilità dei Dati Personali di terzi ottenuti, pubblicati o condivisi mediante questo Sito Web e garantisce di avere il diritto di comunicarli o diffonderli, liberando il Titolare da qualsiasi responsabilità verso terzi. </p>
                    <br></br>
                    <br></br>
                    <p><h4><b>TIPOLOGIE, MODALITÀ E LUOGO DEL TRATTAMENTO DEI DATI RACCOLTI</b></h4></p>
                    <br></br>
                    <h5>Methods of processing</h5>
                    <p>Il Titolare adotta le opportune misure di sicurezza volte a impedire l’accesso, la divulgazione, la modifica o la distruzione non autorizzate dei Dati Personali.<br></br>
Il trattamento viene effettuato mediante strumenti informatici e/o telematici, con modalità organizzative e con logiche strettamente correlate alle finalità indicate. Oltre al Titolare, in alcuni casi, potrebbero avere accesso ai Dati altri soggetti coinvolti nell'organizzazione di questo Sito Web (personale amministrativo, commerciale, marketing, legali, amministratori di sistema) ovvero soggetti esterni (come fornitori di servizi tecnici terzi, corrieri postali, hosting provider, società informatiche, agenzie di comunicazione) nominati anche, se necessario, Responsabili del Trattamento da parte del Titolare. L’elenco aggiornato dei Responsabili potrà sempre essere richiesto al Titolare del Trattamento.</p>
                    <p><h5>Base giuridica del trattamento</h5></p>
                    <p>Il Titolare tratta Dati Personali relativi all’Utente in caso sussista una delle seguenti condizioni:</p>
                    <ul>
                        <li>l’Utente ha prestato il consenso per una o più finalità specifiche; Nota: in alcuni ordinamenti il Titolare può essere autorizzato a trattare Dati Personali senza che debba sussistere il consenso dell’Utente o un’altra delle basi giuridiche specificate di seguito, fino a quando l’Utente non si opponga (“opt-out”) a tale trattamento. Ciò non è tuttavia applicabile qualora il trattamento di Dati Personali sia regolato dalla legislazione europea in materia di protezione dei Dati Personali;</li>
                        <li>il trattamento è necessario all'esecuzione di un contratto con l’Utente e/o all'esecuzione di misure precontrattuali;</li>
                        <li>il trattamento è necessario per adempiere un obbligo legale al quale è soggetto il Titolare;</li>
                        <li>il trattamento è necessario per l'esecuzione di un compito d'interesse pubblico o per l'esercizio di pubblici poteri di cui è investito il Titolare;</li>
                        <li>il trattamento è necessario per il perseguimento del legittimo interesse del Titolare o di terzi.</li>
                    </ul>
                    <p>È comunque sempre possibile richiedere al Titolare di chiarire la concreta base giuridica di ciascun trattamento e in particolare di specificare se il trattamento sia basato sulla legge, previsto da un contratto o necessario per concludere un contratto.</p>
                    <p><h5>Luogo</h5></p>
                    <p>I Dati sono trattati presso le sedi operative del Titolare e in ogni altro luogo in cui le parti coinvolte nel trattamento siano localizzate. Per ulteriori informazioni, contatta il Titolare.
I Dati Personali dell’Utente potrebbero essere trasferiti in un paese diverso da quello in cui l’Utente si trova. Per ottenere ulteriori informazioni sul luogo del trattamento l’Utente può fare riferimento alla sezione relativa ai dettagli sul trattamento dei Dati Personali.</p>
                   <p>L’Utente ha diritto a ottenere informazioni in merito alla base giuridica del trasferimento di Dati al di fuori dell’Unione Europea o ad un’organizzazione internazionale di diritto internazionale pubblico o costituita da due o più paesi, come ad esempio l’ONU, nonché in merito alle misure di sicurezza adottate dal Titolare per proteggere i Dati.</p>
                   <p>Qualora abbia luogo uno dei trasferimenti appena descritti, l’Utente può fare riferimento alle rispettive sezioni di questo documento o chiedere informazioni al Titolare contattandolo agli estremi riportati in apertura.</p>
                    <p><h5>Periodo di conservazione</h5></p>
                    <p>I Dati sono trattati e conservati per il tempo richiesto dalle finalità per le quali sono stati raccolti.</p>
                    <p>Pertanto:</p>
                    <ul>
                        <li>I Dati Personali raccolti per scopi collegati all’esecuzione di un contratto tra il Titolare e l’Utente saranno trattenuti sino a quando sia completata l’esecuzione di tale contratto.</li>
                        <li>I Dati Personali raccolti per finalità riconducibili all’interesse legittimo del Titolare saranno trattenuti sino al soddisfacimento di tale interesse. L’Utente può ottenere ulteriori informazioni in merito all’interesse legittimo perseguito dal Titolare nelle relative sezioni di questo documento o contattando il Titolare.</li>
                    </ul>
                    <p>Quando il trattamento è basato sul consenso dell’Utente, il Titolare può conservare i Dati Personali più a lungo sino a quando detto consenso non venga revocato. Inoltre il Titolare potrebbe essere obbligato a conservare i Dati Personali per un periodo più lungo in ottemperanza a un obbligo di legge o per ordine di un’autorità.</p>
                    <p>Al termine del periodo di conservazioni i Dati Personali saranno cancellati. Pertanto, allo spirare di tale termine il diritto di accesso, cancellazione, rettificazione ed il diritto alla portabilità dei Dati non potranno più essere esercitati.</p>
                    <p><h5>FINALITÀ DEL TRATTAMENTO DEI DATI RACCOLTI</h5></p>
                    <p>I Dati dell’Utente sono raccolti per consentire al Titolare di fornire i propri Servizi, così come per le seguenti finalità: Statistica e Visualizzazione di contenuti da piattaforme esterne.</p>

                    <p>Per ottenere ulteriori informazioni dettagliate sulle finalità del trattamento e sui Dati Personali concretamente rilevanti per ciascuna finalità, l’Utente può fare riferimento alle relative sezioni di questo documento.</p>
                    <p><h5>DETTAGLI SUL TRATTAMENTO DEI DATI PERSONALI</h5></p>
                    <p>I Dati Personali sono raccolti per le seguenti finalità e utilizzando i seguenti servizi:</p>
                    <ul>
                        <li>Statistica</li>
                        I servizi contenuti nella presente sezione permettono al Titolare del Trattamento di monitorare e analizzare i dati di traffico e servono a tener traccia del comportamento dell’Utente.
                    </ul>
                    <b>Google Analytics (Google Inc.)</b><br></br>
                    Google Analytics è un servizio di analisi web fornito da Google Inc. (“Google”). Google utilizza i Dati Personali raccolti allo scopo di tracciare ed esaminare l’utilizzo di questo Sito Web, compilare report e condividerli con gli altri servizi sviluppati da Google.<br></br>
                    Google potrebbe utilizzare i Dati Personali per contestualizzare e personalizzare gli annunci del proprio network pubblicitario.<br></br>
                    Dati Personali raccolti: Cookie e Dati di utilizzo. Luogo del trattamento: Stati Uniti – Privacy Policy – Opt Out. Soggetto aderente al Privacy Shield.<br></br><br></br>
                    <p><b>Monitoraggio conversioni di Facebook</b></p>
                    Il monitoraggio conversioni di Facebook Ads è un servizio di statistiche fornito da Facebook, Inc. che collega i dati provenienti dal network di annunci Facebook con le azioni compiute all'interno di questo Sito Web.
                    <p>Personal Data collected: Cookies and Usage Data.<br></br>
                        Place of processing: United States – Privacy Policy.<br></br>
                        Privacy Shield participant.</p>
                    <ul>
                        <li><b>Visualizzazione di contenuti da piattaforme esterne</b></li>
                        Questo tipo di servizi permette di visualizzare contenuti ospitati su piattaforme esterne direttamente dalle pagine di questo Sito Web e di interagire con essi.
Nel caso in cui sia installato un servizio di questo tipo, è possibile che, anche nel caso gli Utenti non utilizzino il servizio, lo stesso raccolga dati di traffico relativi alle pagine in cui è installato.
                    </ul>
                    <p><b>Typekit (Adobe)</b></p>
                    Typekit è un servizio di visualizzazione di stili di carattere gestito da Adobe Systems Incorporated che permette a questo Sito Web d'integrare tali contenuti all’interno delle proprie pagine.
Dati Personali raccolti: Dati di utilizzo e varie tipologie di Dati secondo quanto specificato dalla Privacy Policy del servizio.
                    <p>Luogo del trattamento: Stati Uniti – Privacy Policy. Soggetto aderente al Privacy Shield.</p>
                    <p><b>Google Maps Widget (Google Inc.)</b></p>
                    Google Maps è un servizio di visualizzazione di mappe gestito da Google Inc. che permette a questo Sito Web d'integrare tali contenuti all’interno delle proprie pagine.
Dati Personali raccolti: Cookie e Dati di utilizzo.
                    <p>Luogo del trattamento: Stati Uniti – Privacy Policy. Soggetto aderente al Privacy Shield.</p>
                    <p><h5>ULTERIORI INFORMAZIONI SUI DATI PERSONALI</h5></p>
                    <p><b>Refresh state</b></p>
                    Questo cookie tecnico permette di vedere sempre l'ultimo contenuto aggiornato sul nostro sito.
                    <br></br>
                    <br></br>
                    <h2>Diritti dell'utente</h2>
                    <p>Gli Utenti possono esercitare determinati diritti con riferimento ai Dati trattati dal Titolare.</p>
                    <p>In particolare, l’Utente ha il diritto di:</p>
                    <ul>
                        <li>revocare il consenso in ogni momento. L’Utente può revocare il consenso al trattamento dei propri Dati Personali precedentemente espresso.</li>
                        <li>opporsi al trattamento dei propri Dati. L’Utente può opporsi al trattamento dei propri Dati quando esso avviene su una base giuridica diversa dal consenso. Ulteriori dettagli sul diritto di opposizione sono indicati nella sezione sottostante.</li>
                        <li>accedere ai propri Dati. L’Utente ha diritto a ottenere informazioni sui Dati trattati dal Titolare, su determinati aspetti del trattamento e a ricevere una copia dei Dati trattati.</li>
                        <li>verificare e chiedere la rettificazione. L’Utente può verificare la correttezza dei propri Dati e richiederne l’aggiornamento o la correzione.</li>
                        <li>ottenere la limitazione del trattamento. Quando ricorrono determinate condizioni, l’Utente può richiedere la limitazione del trattamento dei propri Dati. In tal caso il Titolare non tratterà i Dati per alcun altro scopo se non la loro conservazione.</li>
                        <li>ottenere la cancellazione o rimozione dei propri Dati Personali. Quando ricorrono determinate condizioni, l’Utente può richiedere la cancellazione dei propri Dati da parte del Titolare.</li>
                        <li>ricevere i propri Dati o farli trasferire ad altro titolare. L’Utente ha diritto di ricevere i propri Dati in formato strutturato, di uso comune e leggibile da dispositivo automatico e, ove tecnicamente fattibile, di ottenerne il trasferimento senza ostacoli a un altro titolare. Questa disposizione è applicabile quando i Dati sono trattati con strumenti automatizzati e il trattamento è basato sul consenso dell’Utente, su un contratto di cui l’Utente è parte o su misure contrattuali ad esso connesse.</li>
                        <li>proporre reclamo. L’Utente può proporre un reclamo all’autorità di controllo della protezione dei dati personali competente o agire in sede giudiziale.</li>
                    </ul>
                    <p><b>Dettagli sul diritto di opposizione</b></p>
                    Quando i Dati Personali sono trattati nell’interesse pubblico, nell’esercizio di pubblici poteri di cui è investito il Titolare oppure per perseguire un interesse legittimo del Titolare, gli Utenti hanno diritto ad opporsi al trattamento per motivi connessi alla loro situazione particolare.<br></br>
                    Si fa presente agli Utenti che, ove i loro Dati fossero trattati con finalità di marketing diretto, possono opporsi al trattamento senza fornire alcuna motivazione. Per scoprire se il Titolare tratti dati con finalità di marketing diretto gli Utenti possono fare riferimento alle rispettive sezioni di questo documento.
                    <p><b>Come esercitare i diritti</b></p>
                    Per esercitare i diritti dell’Utente, gli Utenti possono indirizzare una richiesta agli estremi di contatto del Titolare indicati in questo documento. Le richieste sono depositate a titolo gratuito ed evase dal Titolare nel più breve tempo possibile, in ogni caso entro un mese.
                    <br></br>
                    <br></br>
                    <br></br>
                    <h1 id = "cookie">Cookie Policy</h1>
                    <p>Questo Sito Web fa utilizzo di Cookie:<br></br>
                    I Cookie sono costituiti da porzioni di codice installate all'interno del browser che assistono il Titolare nell’erogazione del Servizio in base alle finalità descritte. Alcune delle finalità d'installazione dei Cookie potrebbero, inoltre,
                    necessitare del consenso dell'Utente.</p>
                    <p>Quando l’installazione di Cookies avviene sulla base del consenso, tale consenso può essere revocato liberamente in ogni momento seguendo le istruzioni contenute in questo documento.</p>
                    <p><b>Cookie tecnici e di statistica aggregata</b></p>
                    <ul>
                        <li>Attività strettamente necessarie al funzionamento. Questo Sito Web utilizza Cookie per salvare la sessione dell'Utente e per svolgere altre attività strettamente necessarie al funzionamento di questo Sito Web, ad esempio in relazione alla distribuzione del traffico.</li>
                        <li>Attività di salvataggio delle preferenze, ottimizzazione e statistica. Questo Sito Web utilizza Cookie per salvare le preferenze di navigazione e ottimizzare l'esperienza di navigazione dell'Utente. Fra questi Cookie rientrano, ad esempio, quelli per impostare la lingua e la valuta o per la gestione di statistiche da parte del Titolare del sito.</li>
                    </ul>
                    <p><b>Altre tipologie di Cookie o strumenti terzi che potrebbero installarne</b></p>
                    Alcuni dei servizi elencati di seguito raccolgono statistiche in forma aggregata e anonima e potrebbero non richiedere il consenso dell'Utente o potrebbero essere gestiti direttamente dal Titolare – a seconda di quanto descritto – senza l'ausilio di terzi.
                    <p>Qualora fra gli strumenti indicati in seguito fossero presenti servizi gestiti da terzi, questi potrebbero – in aggiunta a quanto specificato e anche all’insaputa del Titolare – compiere attività di tracciamento dell’Utente. Per informazioni dettagliate in merito, si consiglia di consultare le Privacy Policy dei servizi elencati.</p>
                    <p><b>Statistica</b></p>
                    I servizi contenuti nella presente sezione permettono al Titolare del Trattamento di monitorare e analizzare i dati di traffico e servono a tener traccia del comportamento dell’Utente.
                    <ul>
                        <li><b>Google Analytics (Google Inc.)</b></li>
                        <li><b>Monitoraggio conversioni di Facebook</b></li>
                    </ul>
                    <p><b>Visualizzazione di contenuti da piattaforme esterne</b></p>
                    Questo tipo di servizi permette di visualizzare contenuti ospitati su piattaforme esterne direttamente dalle pagine di questo Sito Web e di interagire con essi.
Nel caso in cui sia installato un servizio di questo tipo, è possibile che, anche nel caso gli Utenti non utilizzino il servizio, lo stesso raccolga dati di traffico relativi alle pagine in cui è installato.
                   <ul>
                        <li><b>Google Maps Widget (Google Inc.)</b></li>   
                    </ul> 
                    <p><b>Come posso esprimere il consenso all'installazione di Cookie?</b></p>
                    In aggiunta a quanto indicato in questo documento, l'Utente può gestire le preferenze relative ai Cookie direttamente all'interno del proprio browser ed impedire – ad esempio – che terze parti possano installarne. Tramite le preferenze del browser è inoltre possibile eliminare i Cookie installati in passato, incluso il Cookie in cui venga eventualmente salvato il consenso all'installazione di Cookie da parte di questo sito. L'Utente può trovare informazioni su come gestire i Cookie con alcuni dei browser più diffusi ad esempio ai seguenti indirizzi: Google Chrome, Mozilla Firefox, Apple Safari e Microsoft Internet Explorer.
                    <p>Con riferimento a Cookie installati da terze parti, l'Utente può inoltre gestire le proprie impostazioni e revocare il consenso visitando il relativo link di opt-out (qualora disponibile), utilizzando gli strumenti descritti nella Privacy Policy della terza parte o contattando direttamente la stessa.</p>
                    <p>Fermo restando quanto precede, l’Utente può avvalersi delle informazioni fornite da EDAA (UE), Network Advertising Initiative (USA) e Digital Advertising Alliance (USA), DAAC (Canada), DDAI (Giappone) o altri servizi analoghi. Con questi servizi è possibile gestire le preferenze di tracciamento della maggior parte degli strumenti pubblicitari. Il Titolare, pertanto, consiglia agli Utenti di utilizzare tali risorse in aggiunta alle informazioni fornite dal presente documento.</p>
                    <p>Dal momento che l'installazione di Cookie e di altri sistemi di tracciamento operata da terze parti tramite i servizi utilizzati all'interno di questo Sito Web non può essere tecnicamente controllata dal Titolare, ogni riferimento specifico a Cookie e sistemi di tracciamento installati da terze parti è da considerarsi indicativo. Per ottenere informazioni complete, l’Utente è invitato a consultare la Privacy Policy degli eventuali servizi terzi elencati in questo documento.</p>
                    <p>Vista l'oggettiva complessità di identificazione delle tecnologie basate sui Cookie l'Utente è invitato a contattare il Titolare qualora volesse ricevere qualunque approfondimento relativo all'utilizzo dei Cookie stessi tramite questo Sito Web.</p>
                    <p><b>Riferimenti legali</b></p>
                    La presente informativa privacy è redatta sulla base di molteplici ordinamenti legislativi, inclusi gli artt. 13 e 14 del Regolamento (UE) 2016/679.<br></br>
Ove non diversamente specificato, questa informativa privacy riguarda esclusivamente questo Sito Web.
                    <br></br>
                    <br></br>
                    <h2>Ulteriori informazioni sul trattamento</h2>
                    <p><b>Difesa in giudizio</b></p>
                    I Dati Personali dell’Utente possono essere utilizzati da parte del Titolare in giudizio o nelle fasi preparatorie alla sua eventuale instaurazione per la difesa da abusi nell'utilizzo di questo Sito Web o dei Servizi connessi da parte dell’Utente.<br></br>
                    L’Utente dichiara di essere consapevole che il Titolare potrebbe essere obbligato a rivelare i Dati per ordine delle autorità pubbliche.
                    <p><b>Informative specifiche</b></p>
                    Su richiesta dell’Utente, in aggiunta alle informazioni contenute in questa Privacy Policy, questo Sito Web potrebbe fornire all'Utente delle informative aggiuntive e contestuali riguardanti Servizi specifici, o la raccolta e il trattamento di Dati Personali.
                    <p><b>Log di sistema e manutenzione</b></p>
                    Per necessità legate al funzionamento e alla manutenzione, questo Sito Web e gli eventuali servizi terzi da essa utilizzati potrebbero raccogliere log di sistema, ossia file che registrano le interazioni e che possono contenere anche Dati Personali, quali l’indirizzo IP Utente.
                    <p><b>Informazioni non contenute in questa policy</b></p>
                    Ulteriori informazioni in relazione al trattamento dei Dati Personali potranno essere richieste in qualsiasi momento al Titolare del Trattamento utilizzando gli estremi di contatto.
                    <p><b>Risposta alle richieste "Do Not Track”</b></p>
                    Questo Sito Web non supporta le richieste "Do Not Track”.<br></br>
Per scoprire se gli eventuali servizi di terze parti utilizzati le supportino, l'Utente è invitato a consultare le rispettive Privacy Policy.
                    <p><b>Modifiche a questa Privacy Policy</b></p>
                    Il Titolare del Trattamento si riserva il diritto di apportare modifiche alla presente Privacy Policy in qualunque momento dandone informazione agli Utenti su questa pagina e, se possibile, su questo Sito Web nonché, qualora<br></br> tecnicamente e legalmente fattibile, inviando una notifica agli Utenti attraverso uno degli estremi di contatto di cui è in possesso il Titolare. Si prega dunque di consultare regolarmente questa pagina, facendo riferimento alla data di ultima modifica indicata in fondo.
                    <p>Qualora le modifiche interessino trattamenti la cui base giuridica è il consenso, il Titolare provvederà a raccogliere nuovamente il consenso dell’Utente, se necessario.</p>
                    <br></br>
                    <br></br>
                    <h2>Condizioni d'uso</h2>
                    <p><b>In Sintesi</b></p>
                    Questo sito è di proprietà di F.I.L. CASA AGENCY S.r.l., di seguito “Società”, con sede in VIA PINDEMONTE 2 – MILANO.<br></br>
                    <p>Tutte le pagine riassumono in modo conciso numerose informazioni e riportano molte immagini rappresentative. Ragion per cui, onde evitare un’errata comprensione dei contenuti si rimanda a mirati approfondimenti con un responsabile dedicato.</p>
                    <p><b>Approfondimenti</b></p>
                    Si consiglia di leggere attentamente quanto di seguito riportato se si desidera accedere al sito web stesso, poiché la navigazione comporta l’accettazione di tutte le condizioni elencate:
                    <ul>
                        <li>I servizi offerti dal sito web sono accessibili in generale senza registrazione degli Utenti. Ove richiesta la registrazione verrà opportunamente segnalato.</li>
                        <li>La SOCIETÀ è la legittima titolare del sito web, delle pagine che comprende, le informazioni, disegni, grafica, database, loghi, marchi, nomi commerciali o altri segni protetti da diritti di proprietà intellettuale o industriale.</li>
                        <li>La SOCIETÀ non garantisce la continuità di accesso né la corretta visualizzazione e il download di contenuti che possono essere ostacolati e/o interrotti da fattori al di fuori del loro controllo.</li>
                        <li>La SOCIETÀ non è responsabile per le informazioni e i contenuti di siti di terzi accessibili dal presente sito web. L'utente potrà comunque segnalare eventuali link illegali e/o sospetti inviando una segnalazione contattandoci via e-mail.</li>
                        <li>La SOCIETÀ si riserva il diritto di apportare modifiche che ritiene opportune a contenuti e servizi presenti nel sito web in qualunque momento.</li>
                        <li>Il presente sito web è soggetto a Copyright e tutti i diritti sono riservati. È vietata la riproduzione, anche parziale, di testi e immagini, senza autorizzazione.</li>
                        <li>I dati personali degli utenti saranno trattati in conformità alla normativa vigente. Per ulteriori informazioni, vi preghiamo di prendere visione del regolamento nell'apposita sezione sulla Privacy.</li>
                    </ul>
                    <br></br>
                    <br></br>
                    <h2>Note specifiche sui contenuti, testi e immagini</h2>
                    <p>Tutta la documentazione tecnica e grafica presente sul sito web, modelli 3D, proposte di recupero e di arredo, sono puramente indicative e rappresentano esclusivamente una base illustrativa, non costituiscono quindi elemento contrattuale. Una parte delle immagini riportate nel sito web è sviluppata con tecniche di computer grafica (rendering fotorealistico), con riferimento a particolari di facciata e prospetti, parti comuni, parti private e interni degli appartamenti, al solo fine di prospettare un’ipotesi di futura realizzazione e/o potenzialità delle diverse soluzioni a seguito di personalizzazione degli utenti. Ragion per cui tutte le informazioni, descrizioni e immagini sono puramente suggestive e rappresentative, quindi soggette a variazioni in corso d’opera.</p>
                    </div>
                
                </Container>
            </>
        );
    }
}

export default Privacy;