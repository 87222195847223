import * as React from "react";
import BodyHome from "../components/BodyHome/bodyHome";
import Footer from "../components/Footer/footer";
import Menu from "../components/Menu/menu";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';

const pageStyles = {color: "#232129"}

//this.props.parentCallback1(this.state.showMapCity);

const HomePage = () => {
  return (
    <main style={pageStyles}>
      <Menu/>
      <BodyHome></BodyHome>
      <Footer></Footer>
    </main>
  )
}

export default HomePage
